import { rest } from 'msw'
import { ordersHandlers } from '../../orders/__mocks__/ordersHandlers'
import { eSignHandlers } from '../../eSign/__mocks__/eSignHandlers'

export const handlers = [
  rest.get('/favicon.ico', (req) => {
    return req.passthrough()
  }),
  /**
   * Orders Handlers
   * ===============
   */
  ...ordersHandlers,
  /**
   * ESign Handlers
   * ==============
   */
  // Handles GET /Packages request
  ...eSignHandlers
]