import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { CheckRounded, PriorityHighRounded } from '@mui/icons-material';
import { string } from "yup";

interface IProps {
  label?: string,
  questions: any,
  answerValue: string,
  questionValue: string,
  customQuestionValue?: string,
  onUpdate?: any
}

interface FormValues {
  question: string,
  customQuestion: string,
  answer: string
}

export default function KBAQuestion(props: IProps) {
  const { label, questions, answerValue, questionValue, customQuestionValue, onUpdate } = props

  const validationSchema = yup.object({
    question: string()
      .min(3, 'Please select a question'),
    customQuestion: string()
      .when("question", ([question], schema) =>
        question?.toLowerCase() === 'custom'
            ? schema.required("Please enter custom question answer")
            : schema
      ),
    answer: string()
      .when("question", ([question], schema) =>
          question?.length > 0
              ? schema.required('Please enter answer for question one')
              : schema
      )
  })

  const formik: any = useFormik({
    initialValues: {
      question: (questionValue !== '') ? questionValue : '',
      customQuestion: (customQuestionValue !== '') ? customQuestionValue : '',
      answer: (answerValue !== '') ? answerValue : '',
    } as FormValues,
    validationSchema: validationSchema,
    onSubmit: () => { }
  });

  const handleOnChange = (fieldName: string, e: any) => {
    const val = e.target.value
    if (fieldName === 'question') {
      formik.setFieldValue('question', val)
      if (val === '') {
        formik.setFieldValue('customQuestion', '')
        formik.setFieldValue('answer', '')
        onUpdate({
          question: '',
          answer: '',
          customQuestion: ''
        })
      }
      return
    }
    formik.setFieldValue(fieldName, val)
    onUpdate({
      ...formik.values,
      [fieldName]: val
    })
  }

  return (
    <Card
      variant='outlined'
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant='subtitle1'>
            {(label) ? label : 'Question'}
          </Typography>
          {(formik?.values?.question !== '') ? (
            formik?.isValid ? (
              <CheckRounded color='success' />
            ) : (
              <PriorityHighRounded color='error' />
            )
          ) : (
            null
          )}
        </Box>


        <FormControl variant='standard' fullWidth>
          <InputLabel>Question</InputLabel>
          <Select
            label='Question'
            id="question"
            name="question"
            value={formik.values.question}
            onChange={(val: any) => handleOnChange('question', val)}
          >
            <MenuItem value=''>None</MenuItem>
            {questions.map((question: string, index: number) =>
              <MenuItem key={index} value={question}>{question}</MenuItem>
            )}
            <MenuItem value='Custom'>Custom Question</MenuItem>


          </Select>
        </FormControl>

        <TextField
          sx={{
            display: (formik?.values?.question?.toLowerCase() === 'custom') ? 'auto' : 'none'
          }}
          fullWidth
          margin="dense"
          type="string"
          variant="standard"
          id="customQuestion"
          name="customQuestion"
          label="Custom Question"
          value={formik.values.customQuestion}
          onChange={(val: any) => handleOnChange('customQuestion', val)}
          onBlur={formik.handleBlur}
          error={formik.touched.customQuestion && Boolean(formik.errors.customQuestion)}
          helperText={formik.touched.customQuestion && formik.errors.customQuestion}
        />

        <TextField
          sx={{
            display: (formik.values.question?.length !== 0 || formik?.values?.question?.toLowerCase() === 'custom') ? 'auto' : 'none'
          }}
          fullWidth
          margin="dense"
          type="string"
          variant="standard"
          id="answer"
          name="answer"
          label="Answer"
          value={formik.values.answer}
          onChange={(val: any) => handleOnChange('answer', val)}
          onBlur={formik.handleBlur}
          error={formik.touched.answer && Boolean(formik.errors.answer)}
          helperText={formik.touched.answer && formik.errors.answer}
        />
      </CardContent>

    </Card>
  );
}