import axiosInterceptor from '../../shared/utils/axios'
import axios, { AxiosResponse } from 'axios'

import {
  ConfigIProps
} from './transactionTypes'


export const beginFileDownload = (path: string, requestParams: Record<string, unknown>, config: ConfigIProps) => {
  
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
  function poll (fn: any, retries = Infinity, timeoutBetweenAttempts = 1000) {
    return Promise.resolve()
    .then(fn)
    .catch(function retry (err):Promise<unknown> {
      if (retries-- > 0) {
        return delay(timeoutBetweenAttempts)
        .then(fn)
        .catch(retry)
      }
      throw err
    })
  }

  return new Promise<any>((resolve, reject) => {
    const url = config.baseURL + path
    axiosInterceptor.get(url, { params: requestParams })
      .then((response) => {
        const firstFileName = Object.keys(response.data.AuthorizationHeaders)[0]
        const executionArn = response?.data?.ExecutionArn
        const url = (response?.data?.AuthorizationHeaders[firstFileName]) ? response?.data?.AuthorizationHeaders[firstFileName] : undefined
        const pollingParams = {
          ExecutionArn: executionArn,
          DelayInMilliseconds: 1000,
          NumberRetries: 15
        }
        if (url && firstFileName && !executionArn) {
          downloadFile(response, firstFileName)
          .then(() => {
            // download worked
            resolve(undefined)
          })
          .catch(error => {
            reject(error.message)
          })
          return
        }
        if (url && firstFileName && executionArn) {
          const validate = (res: any) => {
            if (res.data.Status === 'FAILED') {
              if (res.data.Error === 'RecordNotFoundRequestException') {
                reject('There are no documents available for download')
              } else {
                reject('Failed to Retrieve Document, Please Try Again')
              }
              return
            }
            if (!res.data || res.data.Status !== 'SUCCEEDED') {
              throw res
            } else {
              downloadFile(response, firstFileName)
              .then(() => {
                // download worked
                resolve(undefined)
              })
              .catch(error => {
                reject(error.message)
              })
            }
          }
          return poll(() => axiosInterceptor.get(config.baseURL + '/order-management/get-workflow-status', { params: pollingParams }).then(validate), 5, 3000)
        } else {
          reject('No presigned url provided')
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          /// Request canceled...
          resolve(undefined)
        } else {
          reject((error?.response?.data?.message) ? error.response.data.message : error.message)
        }
      })
  })
}
  


export const downloadFile = (response: AxiosResponse<any>, fileName: string) => {
  return new Promise((resolve, reject) => {
    axios({
      url: response.data.AuthorizationHeaders[fileName].URL,
      method: 'GET',
      headers: response.data.AuthorizationHeaders[fileName].Headers,
      responseType: 'blob', // Important
    }).then((res) => {
  
      const link = document.createElement('a');
      link.download = fileName;
      link.target= '_self'
      const blob = new Blob([res.data], {type: 'application/pdf'});
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
      window.close();
      resolve(res)
    }).catch((error) => {
      reject(error)
    })
  })
}
