import { Box } from '@mui/material'
import { ReactNode } from 'react';

export interface ISectionHeaderProps {
  title: string;
  component?: ReactNode;
}

export default function SectionHeader (props: ISectionHeaderProps) {
  const {
    title,
    component
  } = props
  return(
    <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'primary.main',
          px: theme => theme.spacing(2),
          py: theme => theme.spacing(1),
          color: 'primary.contrastText',
          my: theme => theme.spacing(1)
        }}>
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {component  && 
            <Box sx={{ flexShrink: 1 }}>
              {component}
            </Box>
          }
      </Box>
  )
}