import { APIDocumentUploadStatus, DocumentUploadStatus } from "./enums"


export interface LocalDocumentUploadStatus {
    status: DocumentUploadStatus;
    errorMessage: string|null
    errorUploading: boolean,
    uploading: boolean
}

export const getMappedUploadStatus = (payloadStatus: string | undefined) => {
    const localDocumentUploadStatus: LocalDocumentUploadStatus = { status: DocumentUploadStatus.Uploading, errorMessage: null, errorUploading: false, uploading: true };
    
    switch (payloadStatus) {
        case APIDocumentUploadStatus.Encrypted:
            localDocumentUploadStatus.status = DocumentUploadStatus.Complete;
            localDocumentUploadStatus.uploading = false
            break;
        case APIDocumentUploadStatus.ScanFailure:
            localDocumentUploadStatus.errorMessage = "Scan failure - file failed scan";
            localDocumentUploadStatus.errorUploading = true;
            localDocumentUploadStatus.status = DocumentUploadStatus.Failed;
            localDocumentUploadStatus.uploading = false
            break;
        case APIDocumentUploadStatus.EncryptionFailure:
            localDocumentUploadStatus.errorMessage = "Error uploading file";
            localDocumentUploadStatus.errorUploading = true;
            localDocumentUploadStatus.status = DocumentUploadStatus.Failed;
            localDocumentUploadStatus.uploading = false
            break;
        case APIDocumentUploadStatus.Infected:
            localDocumentUploadStatus.errorMessage = "The uploaded file appears to violate Security Policy. Please upload a different file...";
            localDocumentUploadStatus.errorUploading = true;
            localDocumentUploadStatus.status = DocumentUploadStatus.Failed;
            localDocumentUploadStatus.uploading = false
    }

    return localDocumentUploadStatus;
}