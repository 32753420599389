import { RootState } from '../redux-store/store';
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

const PublicRoute = ({children = {}}) => {
  const orderId = window.localStorage.getItem('orderId')

  const redirectUrl = () => {
    if (orderId) {
      return orderId
    } else {
      return '/dashboard'
    }
  }

  const replaceUrl = () => {
    if (orderId) {
      return true
    } else {
      return false
    }
  }

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  return (
    <>
      {(isLoggedIn) ? (
        <Navigate 
          to={redirectUrl()} 
          replace={replaceUrl()} />
      ) : (
        children
      )}
    </>
  )
}

export default PublicRoute
