import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { normalizePhoneInput } from '../../../shared/utils/helpers';
import { ICreatePackageSignerListValues } from '../../../../types/esign/createPackage';

interface IProps {
  open: boolean,
  edit?: boolean,
  close: any,
  submit: any,
  signer: null | any,
  addedSigners: any
}

interface FormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  email: string;
  phoneNumber: string;
}

export default function AddSignerDialog(props: IProps) {
  const { open, close, submit, signer, edit, addedSigners } = props

  const validationSchema = yup.object({
    firstName: yup.string()
      .min(1, 'Please enter your First Name')
      .required('First Name is required'),
    middleName: yup
      .string(),
    lastName: yup
      .string()
      .min(1, 'Please enter your Last Name')
      .required('Last Name is required'),
    suffix: yup
      .string(),
    email: yup
      .string()
      .email('Enter a valid Email')
      .test('exists', 'Signer with this email already in use', (val:any) => {
        if (edit) {
          if(val !== formik.initialValues.email){
            return doesSignerExist(val);
          }
        } else {
          return doesSignerExist(val);
        }
        return true;
      })
      .required('Email is required'),
    phoneNumber: yup
      .string()
      .min(14, 'Please enter full phone number')
  });

  const formik = useFormik({
    initialValues: {
      firstName:  (signer && signer?.firstName) ? signer.firstName : '',
      middleName: (signer && signer?.middleName) ? signer.middleName : '',
      lastName: (signer && signer?.lastName) ? signer.lastName : '',
      suffix: (signer && signer?.suffix) ? signer.suffix : '',
      email: (signer && signer?.email) ? signer.email : '',
      phoneNumber: (signer && signer?.phoneNumber) ? signer.phoneNumber : '',
    } as FormValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  });

  const handleSubmit = () => {
    const updatedValues:any = {...formik.values}
    formik.resetForm()
    submit(updatedValues)
  };

  function doesSignerExist(email: any) {
    const signer = addedSigners.find((signer: ICreatePackageSignerListValues) => signer?.email?.toLocaleLowerCase() === email?.toLocaleLowerCase())
    return !signer
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Add {(signer && signer?.type) ? signer.type : 'Signer'}</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            fullWidth
            autoFocus
            required
            margin="dense"
            type="string"
            variant="standard"
            id="firstName"
            data-testid="firstName"
            name="firstName"
            label="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
          <TextField
            fullWidth
            margin="dense"
            type="string"
            variant="standard"
            id="middleName"
            data-testid="middleName"
            name="middleName"
            label="Middle Name"
            value={formik.values.middleName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.middleName && Boolean(formik.errors.middleName)}
            helperText={formik.touched.middleName && formik.errors.middleName}
          />
          <TextField
            fullWidth
            required
            margin="dense"
            type="string"
            variant="standard"
            id="lastName"
            name="lastName"
            label="Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
          <TextField
            fullWidth
            margin="dense"
            type="string"
            variant="standard"
            id="suffix"
            name="suffix"
            label="Suffix"
            value={formik.values.suffix}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.suffix && Boolean(formik.errors.suffix)}
            helperText={formik.touched.suffix && formik.errors.suffix}
          />
          <TextField
            fullWidth
            required
            margin="dense"
            type="email"
            variant="standard"
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            fullWidth
            margin="dense"
            type="tel"
            variant="standard"
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            value={formik.values.phoneNumber}
            onChange={(e) => formik.setFieldValue('phoneNumber', normalizePhoneInput(e.target.value).trimStart())}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => close()}>Cancel</Button>
        <Button data-testid="submitButton" disabled={!formik.isValid || !formik.dirty} variant='contained' color='primary' onClick={() => handleSubmit()}>{(edit) ? 'Update' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
}