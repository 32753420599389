import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {selectConfig} from '../global/globalSlice'
import {fetchAuditLog, fetchDocuments, fetchSessionUsers, addSessionUser, fetchTransaction, sendPartyLinks, unlockParty} from './transactionApi'
import {RootState} from '../../redux-store/store'
import {DownloadFileIProps, ITransactionView} from './transactionTypes'
import {beginFileDownload} from './transactionFileDownloadAPI'
import {IDocument} from '../../../types/Document'
import {DocDownloadType} from '../../enums'
import {retrieveS3Document} from '../../shared/utils/retrieveS3Document'
import { ISessionUser } from '../../../types/SessionUser'

export interface ITransactionState {
  data?: ITransactionView;
  fetchingTransaction: boolean;
  showDetailsPanel: boolean;
  error: boolean;
  message: Record<string, unknown>;
  isLoading: boolean;
  alertUser: Record<string, unknown> | null;
  documentsListDictionary: Record<string, IDocument[]>
}

export const initialState: ITransactionState = {
  data: undefined,
  fetchingTransaction: false,
  showDetailsPanel: false,
  error: false,
  message: {},
  isLoading: false,
  alertUser: null,
  // TODO transition away from the the single bool to the records
  documentsListDictionary: {[DocDownloadType.Individual]: [], [DocDownloadType.MergedDocs]: [], [DocDownloadType.AuditLog]: []}
}

export const fetchTransactionAsync = createAsyncThunk(
  'transaction/fetchTransactionAsync',
  async (transactionId: any, { getState, rejectWithValue  }) => {
    const config: any = selectConfig(getState() as any)
    try {
      const response = await fetchTransaction('SessionId=' + transactionId, config)
      return response
    } catch (err: any) {
      return rejectWithValue({transactionId,error:err})
    }
  }
)

export const beginFileDownloadAsync = createAsyncThunk(
  'orders/beginFileDownloadAsync',
  async ( data: DownloadFileIProps, { getState, rejectWithValue  }) => {
    const {path, requestParams} = data;
    const config: any = selectConfig(getState() as any);
    try {
      const response = await beginFileDownload(path, requestParams, config);
      return response
    } catch (err:string | unknown) {
      return rejectWithValue(err)
    }
  }
)

export const fetchDocumentsListAsync = createAsyncThunk(
  'transaction/fetchDocumentsListAsync',
  async (queryObj: { transactionId: string | undefined }, { getState, rejectWithValue }) => {
    const config: any = selectConfig(getState() as any);
    const query = 'SessionId=' + queryObj.transactionId + '&MergeDocs=false'
    try {
      const response = await fetchDocuments(query, config)
      return response.data.DocList
    } catch (err:any) {
      return rejectWithValue(err)
    }
  }
)

export const fetchDocumentsPackageAsync = createAsyncThunk(
  'transaction/fetchDocumentsPackageAsync',
  async (queryObj: { transactionId: string | undefined }, { getState, rejectWithValue }) => {
    const config: any = selectConfig(getState() as any);
    const query = 'SessionId=' + queryObj.transactionId + '&MergeDocs=true'
    try {
      const response = await fetchDocuments(query, config)
      return response.data.DocList[0]
    } catch (err:any) {
      return rejectWithValue(err)
    }
  }
)

export const fetchAuditLogAsync = createAsyncThunk(
  'transaction/fetchAuditLogAsync', 
  async (queryObj: { transactionId: string | undefined}, { getState, rejectWithValue }) => {
    const config: any = selectConfig(getState() as any);
    const query = 'SessionId=' + queryObj.transactionId
    try {
      const response = await fetchAuditLog(query, config)
      return response.data
    } catch (err:any) {
      return rejectWithValue(err)
    }
  }
  )

export const openPresignedUrl = createAsyncThunk(
  'transaction/openPresignUrl',
  async (queryObj: { presignedUrl: string } , { rejectWithValue }) => {
    try {
      return await retrieveS3Document(queryObj.presignedUrl)
    } catch (err : any) {
      return rejectWithValue(err)
    }
  }
)

export const unlockPartyAsync = createAsyncThunk(
  'transaction/unlockParty',
  async (object: Record<string, unknown>, { getState, rejectWithValue }) => {
    const config: any = selectConfig(getState() as any);
    try {
      const response = await unlockParty(object, config);
      if (response && !response.IsSuccessful) {
        return rejectWithValue(response.ErrorMessage)
      }
      return response
    } catch (err: string | unknown) {
      return rejectWithValue(err)
    }
  }
)

export const sendPartyLinksAsync = createAsyncThunk(
    'transaction/sendPartyLinks',
    async (queryObj: { SolexSessionId: string | undefined, PartyIds: string | number }, { getState, rejectWithValue }) => {
        const config: any = selectConfig(getState() as any);
        const query = 'SolexSessionId=' + queryObj.SolexSessionId + '&PartyIds=' + queryObj.PartyIds
        try {
            const response =  await sendPartyLinks(query, config);
            if (response && response.error) {
                return rejectWithValue(response.error.message)
            }
            return response.status
        } catch (err: string | unknown) {
            return rejectWithValue(err)
        }
    }
)

export const fetchSessionUsersAsync = createAsyncThunk(
  'transaction/fetchSessionUsers',
  async (sessionId: string, { getState, rejectWithValue }) => {
    const config: any = selectConfig(getState() as any);
    try {
      const sessionUsers = await fetchSessionUsers(sessionId, config)

      return sessionUsers
    } catch (err:any) {
      return rejectWithValue(err)
    }
  }
)

export const addSessionUserAsync = createAsyncThunk(
  'transaction/addSessionUser',
  async (object: { sessionId: string, emailAddress: string }, { getState, rejectWithValue, fulfillWithValue }) => {
    const config: any = selectConfig(getState() as any);
    try {
      await addSessionUser(object.sessionId, object.emailAddress, config)
      return fulfillWithValue({emailAddress: object.emailAddress})
    } catch (err:any) {
      return rejectWithValue(err)
    }
  }
)

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setShowDetailsPanel: (state:any, action: PayloadAction<boolean>) => {
      state.showDetailsPanel = action.payload
    },
    resetTransactionData: (state: any) => {
      state.data = {}
      state.documentsListDictionary = {...initialState.documentsListDictionary}
    },
    resetAlert: (state: ITransactionState) => {
      state.alertUser = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionAsync.pending, (state: any) => {
        state.fetchingTransaction = true
        state.error = false
        state.alertUser = null
        state.message = {}
      })
      .addCase(fetchTransactionAsync.fulfilled, (state: any, action: any) => {
        state.fetchingTransaction = false
        state.error = false
        state.data = action.payload?.data
        state.message = {}
      })
      .addCase(fetchTransactionAsync.rejected, (state: any, action: any) => {
        state.fetchingTransaction = false
        state.error = true
        state.message = action.payload
      })
      // DOWNLOAD FILES
      .addCase(beginFileDownloadAsync.pending, (state) => {
        state.isLoading = true
        state.alertUser = null
      })
      .addCase(beginFileDownloadAsync.fulfilled, (state:any) => {
        state.isLoading = false
        state.alertUser = null
      })
      .addCase(beginFileDownloadAsync.rejected, (state:any, action:any) => {
        state.isLoading = true
        state.error = true
        state.alertUser = {type:'error', text:action.payload}
      })
      .addCase(fetchDocumentsListAsync.pending, (state: any) => {
        state.documentsListDictionary = {...state.documentsListDictionary, [DocDownloadType.Individual]: []}
        state.alertUser = null
      })
      .addCase(fetchDocumentsListAsync.fulfilled, (state: any, action: any) => {
        state.documentsListDictionary =  {...state.documentsListDictionary, [DocDownloadType.Individual]: action.payload}
      })
      .addCase(fetchDocumentsListAsync.rejected, (state: any) => {
        state.documentsListDictionary = {...state.documentsListDictionary, [DocDownloadType.Individual]: []}
        state.alertUser = { type:'error', text: 'An error occurred. Please try again.' }
      })
      .addCase(fetchDocumentsPackageAsync.pending, (state: any) => {
        state.documentsListDictionary = {...state.documentsListDictionary, [DocDownloadType.MergedDocs]: []}
        state.alertUser = null
      })
      .addCase(fetchDocumentsPackageAsync.fulfilled, (state: any, action: any) => {
        state.documentsListDictionary = {...state.documentsListDictionary, [DocDownloadType.MergedDocs]: [action.payload]}
      })
      .addCase(fetchDocumentsPackageAsync.rejected, (state: any) => {
        state.documentsListDictionary = {...state.documentsListDictionary, [DocDownloadType.MergedDocs]: []}
        state.alertUser = { type: 'error', text: 'An error occurred. Please try again.' }
      })
      .addCase(fetchAuditLogAsync.pending, (state: any) => {
        state.documentsListDictionary = {...state.documentsListDictionary, [DocDownloadType.AuditLog]: []}
        state.alertUser = null
      })
      .addCase(fetchAuditLogAsync.fulfilled, (state: any, action: any) => {
        state.documentsListDictionary = {...state.documentsListDictionary, [DocDownloadType.AuditLog]: [action.payload]}
      })
      .addCase(fetchAuditLogAsync.rejected, (state: any) => {
        state.documentsListDictionary = {...state.documentsListDictionary, [DocDownloadType.AuditLog]: []}
        state.alertUser = { type: 'error', text: 'An error occurred. Please try again.' }
      })
      .addCase(openPresignedUrl.pending, (state: any) => {
        state.alertUser = null
      })
      .addCase(openPresignedUrl.fulfilled, (state: any) => {
        state.alertUser = null
      })
      .addCase(openPresignedUrl.rejected, (state: any) => {
        state.alertUser = { type: 'error', text: 'Unable to download document. Please try downloading again.' }
      })
      // UNLOCK PARTY (reset login)
      .addCase(unlockPartyAsync.pending, (state) => {
        state.alertUser = { type: 'info', text: 'Resetting Party Login...'}
      })
      .addCase(unlockPartyAsync.fulfilled, (state: ITransactionState) => {
        state.alertUser = { type: 'success', text: 'Successfully Reset Party Login' }
      })
      .addCase(unlockPartyAsync.rejected, (state: ITransactionState, action: PayloadAction<any>) => {
        state.alertUser = { type: 'error', text: `${ action && action.payload ? action.payload + ' ' : 'An error occurred.'} Please try again.` }
      })
      // RESEND PARTY LINK
      .addCase(sendPartyLinksAsync.pending, (state) => {
          state.alertUser = null
      })
        .addCase(sendPartyLinksAsync.fulfilled, (state: ITransactionState) => {
            state.alertUser = { type: 'success', text: 'The resend link has been requested. Please allow up to five minutes for this to be completed.'}
        })
        .addCase(sendPartyLinksAsync.rejected, (state: ITransactionState, action: PayloadAction<any>) => {
            state.alertUser = { type: 'error', text: `${ action && action.payload ? action.payload + ' ' : 'An error occurred.'} Please try again.` }
        })
      // FETCH SESSION USERS
      .addCase(fetchSessionUsersAsync.pending, (state) => {
        state.alertUser = null
      })
      .addCase(fetchSessionUsersAsync.fulfilled, (state, action) => {
        const transaction: ITransactionView = Object.assign({}, state.data, {SessionUsers: action.payload})
        state.alertUser = null
        state.data = transaction
      })
      .addCase(fetchSessionUsersAsync.rejected, (state) => {
        state.alertUser = { type: 'error', text: 'An error occurred. Please try again.' }
      })
      // ADD SESSION USER
      .addCase(addSessionUserAsync.pending, (state) => {
        state.alertUser = null
      })
      .addCase(addSessionUserAsync.fulfilled, (state, action) => {
        state.alertUser = { type: 'success', text: 'User added successfully.' }
        state.data = Object.assign({}, state.data, {SessionUsers: [...state.data?.SessionUsers as ISessionUser[], action.payload]})
      })
      .addCase(addSessionUserAsync.rejected, (state) => {
        state.alertUser = { type: 'error', text: 'An error occurred. Please try again.' }
      })
  }
})

export const { setShowDetailsPanel, resetTransactionData, resetAlert } = transactionSlice.actions;
export const selectTransaction = (state: RootState) => state.transaction.data;
export const selectUsers = (state: RootState) : ISessionUser[]  | null => state.transaction.data?.SessionUsers;


export default transactionSlice.reducer
