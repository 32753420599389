import './_base.scss'
import { useEffect, useState, useRef } from 'react'
import Box from '@mui/material/Box';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AboutUs from '../../../orders/components/Dialogs/AboutUs';
import TermOfUse from '../../../orders/components/Dialogs/TermsOfUse';

interface IProps {
  footerHeight?: string | number;
}

export default function Footer(props: IProps) {
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<string>('');

  const handleClickOpen = (contentToShow: string) => {
    setOpen(true);
    setContent(contentToShow)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return (
    <div style={{ height: props.footerHeight ? props.footerHeight : 'auto' }}>
      <div className='footer-container'>
        <Box onClick={preventDefault}>
          <Button className='footer-text' variant='text' onClick={() => handleClickOpen('About Us')}>
            < strong > About Us</strong>
          </Button>
          <Button className='footer-text' variant='text' onClick={() => { window.open('https://www.firstam.com/privacy-policy/#type-of-information/', '_blank') }}>
            <strong>Privacy Policy</strong>
          </Button>
          <Button className='footer-text' variant='text' onClick={() => handleClickOpen('Term of Use')}>
            < strong > Term of Use</strong>
          </Button>
        </Box>
        <span className='footer-text' >
          ©2023 First American Corporation and its licensed subsidiaries. All rights reserved.
        </span>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth={content === 'About Us' ? 'sm' : 'md'}
      >
        <DialogTitle id="scroll-dialog-title">{content}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {content === 'About Us' && (<AboutUs />)}
            {content === 'Term of Use' && (<TermOfUse />)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
