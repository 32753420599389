import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import KBAQuestion from '../KBAQuestion';
import { useState } from 'react';

interface IProps {
  open: boolean,
  headerText?: string,
  close: any,
  submit: any,
  questions: any
}

export default function AddSignerKBADialog(props: IProps) {
  const { open, close, submit, questions, headerText } = props
  const [questionsArray, setQuestionsArray] = useState((questions) ? questions : [])

  const handleSubmit = () => {
    submit(questionsArray)
  };

  const handleUpdate = (questionNumber: number, vals: any) => {
    const updateQuestions = JSON.parse(JSON.stringify(questionsArray))
    const questionExists = updateQuestions?.find((question: any) => question.number === questionNumber)
    if (!checkQValid(vals)) {
      if (questionExists) {
        updateQuestions.splice((questionNumber - 1), 1)
        if(updateQuestions.length === 1) {
          updateQuestions[0].number=1
        }
      }
      setQuestionsArray(updateQuestions)
      return
    }
    if (!questionExists) {
      updateQuestions.push({ ...vals, number: questionNumber })
    } else {
      updateQuestions[questionNumber - 1] = { ...vals, number: questionNumber }
    }
    setQuestionsArray(updateQuestions)
  };

  const clearQuestions = () => {
    submit([])

  }

  const checkQValid = (vals: any) => {
    if (vals && vals?.question) {
      if (vals.question === '') return false
      if (vals.question === 'Custom' && (vals.customQuestion.length === 0 || vals.answer.length === 0)) return false
    } else {
      return false
    }
    return true
  }

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle sx={{justifyContent:'space-between'}}>{(headerText) ? headerText : 'Security Questions'}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: '100%',
            minWidth: '400px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          <KBAQuestion
            label='Question One'
            questions={[
              'What is your zip code?'
            ]}
            answerValue={(questionsArray[0]) ? questionsArray[0]?.answer : ''}
            questionValue={(questionsArray[0]) ? questionsArray[0]?.question : ''}
            customQuestionValue={(questionsArray[0]) ? questionsArray[0]?.customQuestion : ''}
            onUpdate={(vals: any) => handleUpdate(1, vals)}
          />
          {(questionsArray[0] && checkQValid(questionsArray[0])) &&
            <KBAQuestion
                label='Question Two'
                questions={[
                  'What is your zip code?'
                ]}
                answerValue={(questionsArray[1]) ? questionsArray[1]?.answer : ''}
                questionValue={(questionsArray[1]) ? questionsArray[1]?.question : ''}
                customQuestionValue={(questionsArray[1]) ? questionsArray[1]?.customQuestion : ''}
                onUpdate={(vals: any) => handleUpdate(2, vals)}
              />
          }

        </Box>

      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => close()}>Cancel</Button>
        <Button onClick={() => clearQuestions()} size='small' variant='text'>Remove All</Button>
        <Button variant='contained' color='primary' onClick={() => handleSubmit()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}