import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import globalReducer from './global/globalSlice';
import sessionsReducer from './sessions/sessionsSlice'
import transactionReducer from './transaction/transactionSlice'
import esignReducer from './esign/esignSlice'

export const store:any = configureStore({
  reducer: {
    auth: authReducer,
    global: globalReducer,
    sessions: sessionsReducer,
    transaction: transactionReducer,
    esign: esignReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
