export const localConfig = {
  // Local Dev Configs Here
  clientId: '7p622362qiv81huovlfube1m6f',
  baseURL: 'https://api.vision.dev.solex.com',
  esignApiUrl: 'https://esign.vision.dev.solex.com',
  loginURL: {
    base: 'https://vision-dev.auth.us-west-2.amazoncognito.com/oauth2/authorize?',
    params: `client_id=7p622362qiv81huovlfube1m6f&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${'https://' + window.location.host + '/login/callback'}`
  },
  tokenURL: 'https://api.vision.dev.solex.com/auth/token',
  features: {
    enableMocks: false
  },
  cxNowUrl: 'https://now.dev.dev.conformx.com/login',
  appBaseURL: `${'https://' + window.location.host + '/'}`,
  cognitoBaseURL: "https://vision-dev.auth.us-west-2.amazoncognito.com/"
}