
/**
 * @constant UploadErrorMessages
 */

export const UploadErrorMessages = {
  NotPDF: 'One of the files is not a PDF. Only .pdf file types are permitted for upload',
  NotPNG: 'One of the files is not a PNG. Only .png file types are permitted for upload.',
  ContainsForbiddenCharacter: 'One of the file names contains a forbidden character ($ & + , / : ; = ? @). Please remove it and try again.',
  FileSizeLimit: (maxSize:number) => `One of the files is over the ' + ${maxSize} + ' MB limit`
}

