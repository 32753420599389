import { Box, Button, Fade, Paper, Tab, Tabs } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { IStep } from '../../../../types/Stepper'
import CustomStepperTab from '../CustomStepperTab'
import { a11yProps } from '../../utils/helpers'
import { TabPanel } from '../TabPanel'
import { styled } from '@mui/system'
import { StepperActions } from "../../../enums"

/**
 * @component CustomStepper
 * @description A MUI Tab based customized stepper
 * @param steps array of IStep objects
 * @param review single IStep object for review
 * @param hideNav boolean that hides the stepper nav (usefull for locking navigation when submitting)
 * @param linear boolean that forces liniar navigation
 * @param action generic action function
 */


const StepperContainer = styled('div')({
  width: '100%',
  minHeight: ' 400px',
  display: 'flex',
  flexDirection: 'row'
});

const StepperTabs = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
});

const StepperPanels = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  position: 'relative',
  borderLeft: `1px solid ${theme.palette.divider}`
}));

interface IProps {
  steps: IStep[],
  review: IStep,
  hideNav: boolean,
  linear?: boolean,
  action: (type: string) => void
}

export default function CustomStepper(props: IProps) {
  const { steps, review, action, linear, hideNav } = props
  const menuRef = useRef<HTMLDivElement | null>(null)
  const [activeStep, setActiveStep] = useState<number>(0)

  const handleNext = () => {
    if (linear && !steps[activeStep].completed) return
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1)
  }

  const handleBack = (index = -1) => {
    if (activeStep === 0) return
    if (index !== -1) {
      setActiveStep(index)
      return
    }
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1)
  }

  //Scroll nav when stepper content is long
  useEffect(() => {
    const onScroll = () => {
      const doc = document.documentElement
      const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      const menuOffsetTop = menuRef?.current !== null ? menuRef.current.offsetTop : 0

      if (menuRef.current !== null && scrollTop > menuOffsetTop && menuRef.current.children[0] && menuRef.current.children[0].className.includes('manual-stepper-tabs')) {
        menuRef.current.children[0].setAttribute('style', `transform: translateY(${scrollTop - menuOffsetTop}px);`)
      } else {
        if (menuRef?.current?.children[0]) {
          menuRef.current.children[0].setAttribute('style', '')
        }
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  if (!steps || steps?.length === 0) {
    return null
  }


  return (
    <>
      <Fade in style={{ transitionDelay: '200ms' }}>
        <Paper
          sx={{
            width: '100%',
            maxWidth: (theme) => theme.breakpoints.values.lg
          }}
        >
          <StepperContainer
            ref={menuRef}
          >
            {!hideNav &&
              <StepperTabs>
                <Tabs
                  orientation='vertical'
                  variant='scrollable'
                  value={activeStep}
                >
                  {steps?.map((step, index) =>
                    <Tab key={index} label={<CustomStepperTab activeStep={(index === activeStep)} completed={step.completed} label={step.name} index={index} />} {...a11yProps(index)} />
                  )}
                  <Tab label={<CustomStepperTab activeStep={steps?.length === activeStep} completed={review?.completed} label={review?.name || ''} lastStep index={(steps.length)} />} {...a11yProps(0)} />
                </Tabs>

                <Box
                  sx={{
                    marginTop: (theme) => theme.spacing(1),
                    marginLeft: (theme) => theme.spacing(2),
                    marginRight: (theme) => theme.spacing(2),
                  }}
                >
                  <Button
                    fullWidth
                    color='primary'
                    variant='outlined'
                    onClick={() => action(StepperActions.Cancel)}
                  >
                    cancel
                  </Button>
                </Box>

              </StepperTabs>
            }

            <StepperPanels>
              {steps?.map((step, index) =>
                <TabPanel key={index} value={activeStep} index={index}>
                  <step.component nextStep={handleNext} backStep={handleBack} {...step?.props} />
                </TabPanel>
              )}
              <TabPanel value={activeStep} index={steps.length}>
                {review?.component &&
                  <review.component backStep={handleBack} {...review?.props} />}
              </TabPanel>
            </StepperPanels>
          </StepperContainer>
        </Paper>
      </Fade>
    </>
  )
}
