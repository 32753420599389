/**
 * 
 * @param val value(s) to transform
 * @returns 
 */
export const transformPropToPascal = (val: Record<string, unknown>[] | Record<string, unknown>) =>  {
  let transformed: Record<string, unknown>[] | Record<string, unknown>
  if(Array.isArray(val)){
    const newArray: any[] = []
    for (let i = 0; i < val.length; i++) {
      const object = val[i];
      transformed = Object.keys(object).reduce((obj: Record<string, unknown>, key) => {
        obj[key.charAt(0).toUpperCase() + key.slice(1)] = object[key];
        return obj;
      }, {});
      newArray.push(transformed)
    }
    transformed = newArray
  } else {
    transformed = Object.keys(val).reduce((obj: Record<string, unknown>, key) => {
      obj[key.charAt(0).toUpperCase() + key.slice(1)] = val[key];
      return obj;
    }, {});
  }
  return transformed
}