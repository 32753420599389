
import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { beginUploadDocumentsAsync, cancelCreatePackage, submitPackageAsync, updatePackage } from '../../../redux-store/esign/esignSlice';
import CustomStepper from '../../../shared/components/CustomStepper';
import UPSigners from '../../components/UPSigners';
import { ISteps } from '../../../../types/Stepper';
import { RootState } from '../../../redux-store/store';
import { ICreatePackageDetails } from '../../../../types/esign/createPackage';
import CPPackageDetails from '../../components/CPPackageDetails';
import UPUploadDocuments from '../../components/UPUploadDocuments';
import { StepperActions } from '../../../enums';
import UPPackageReview from '../../components/UPPackageReview';
import { validateAddSigners, validateUploadPackageFiles, validateAllSteps } from '../../utils/uploadPackageStepValidator';
import validatePackageDetails from '../../utils/createPackageDetailsValidator';
import { prepPackage } from '../../utils/packagePrepSubmission';
import useDocumentStatusPolling from '../../hooks/useDocumentStatus';

const CTContainer = styled('div')(({ theme }) => ({
  background: theme.palette.grey[100],
  width: '100%',
  minHeight: 'calc(100vh - 45px)'
}));

const CTHeader = styled('div')(({ theme }) => ({
  width: '100%',
  background: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: `0px ${theme.spacing(1)}`,
  marginBottom: `${theme.spacing(3)}`
}));

const CTBody = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
});

export default function UploadNewPackage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const createPackage: ICreatePackageDetails = useAppSelector((state: RootState) => state.esign.createPackage)
  useDocumentStatusPolling();

  const handleAction = (type: string, data?: any) => {
    switch (type) {
      case StepperActions.Cancel: {
        navigate(-1);
        dispatch(cancelCreatePackage());
        break;
      }
      case StepperActions.Update: {
        if (data) {
          dispatch(updatePackage(data));
        }
        break;
      }
      case StepperActions.UploadFile: {
        if (data) {
          dispatch(updatePackage({ ...createPackage, ...{ uploads: createPackage.uploads.concat(data.filesDetails) } }));
          dispatch(beginUploadDocumentsAsync({ files: data.files, filesDetails: data.filesDetails }));
        }
        break;
      }
      case StepperActions.RemoveFile: {
        if (typeof data === 'number') {
          const updatedArray = JSON.parse(JSON.stringify(createPackage.uploads))
          updatedArray.splice(data, 1)
          dispatch(updatePackage({ ...createPackage, ...{ uploads: updatedArray } }));
        }
        break;
      }
      case StepperActions.ReorderList: {
        if (data) {
          dispatch(updatePackage({ ...createPackage, ...{ uploads: data } }));
        }
        break;
      }
      case StepperActions.Submit: {
        if (data) {
          dispatch(submitPackageAsync({ hasUpload: true, readyPackage: prepPackage(createPackage) }))
            .then((res: any) => {
              if (res.payload.status !== 'Error') {
                navigate(`/esign/package/${res.payload}`);
              }
            })
        }
        break;
      }
    } 
  }

  const createPackageSteps: ISteps = {
    steps: [
      {
        component: UPSigners,
        props: {
          data: createPackage,
          action: handleAction
        },
        name: 'Add Signers',
        completed: validateAddSigners(createPackage)
      },
      {
        component: CPPackageDetails,
        props: {
          data: createPackage,
          updatePackage: (data: any) => handleAction(StepperActions.Update, data)
        },
        name: 'Package Details',
        completed: validatePackageDetails(createPackage)
      },
      {
        component: UPUploadDocuments,
        props: {
          data: createPackage,
          action: handleAction
        },
        name: 'Upload Documents',
        completed: validateUploadPackageFiles(createPackage)
      }
    ],
    review: {
      component: UPPackageReview,
      props: {
        data: createPackage,
        editSection: (section: number) => handleAction(StepperActions.Edit, section),
        onSubmit: () => handleAction(StepperActions.Submit, createPackage)
      },
      name: 'Review & Submit',
      completed: (() => {
        return validateAllSteps(createPackage)
      })()
    }
  }


  const handleCancel = () => {
    navigate(-1)
    dispatch(cancelCreatePackage())
  }

  return (
    <CTContainer>
      <CTHeader>
        <Typography color={'white'} variant='h6'>Upload New Package</Typography>
        <IconButton onClick={() => handleCancel()} sx={{ color: 'white' }} aria-label='close'>
          <Close />
        </IconButton>
      </CTHeader>
      <CTBody>
        <CustomStepper
          steps={createPackageSteps.steps}
          review={createPackageSteps.review}
          hideNav={createPackage.isSubmitting}
          action={handleAction}
        />
      </CTBody>
    </CTContainer>
  )
}
