import GridViewIcon from '@mui/icons-material/GridView'
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined'
import { PackageStatusType } from '../../enums';

export interface ITab {
  name: string;
  icon: any;
  route: string;
}

export const ordersTab = 'orders';
export const eSignTab = 'eSign docs';
export const defaultTimezone = 'America/Denver';

export const tabs: Array<ITab> = [
  { name: ordersTab, icon: GridViewIcon, route: '/dashboard' },
  { name: eSignTab, icon: FileOpenOutlinedIcon, route: '/esign' }
]

export const enabledStatuses = [PackageStatusType.Pending, PackageStatusType.Open, PackageStatusType.InProgress, PackageStatusType.ReadyForTagging]