import { ReactNode } from "react"

interface IProps {
  disable: boolean,
  children: ReactNode
}

export default function DisableContainer(props: IProps) {
  const {
    disable = false,
    children
  } = props

  return (
  <div
    data-testid='disable-container'
    style={{
      pointerEvents: (disable) ? 'none':'all',
      userSelect: (disable) ? 'none':'auto',
      filter: (disable) ? 'grayscale(100%)' : 'none',
      opacity: (disable) ? '.5' : '1'
    }}
  >
    {children}
  </div>
  )
}

