import {
  ICreatePackageDetails
} from '../../../types/esign/createPackage'
import validatePackageDetails from './createPackageDetailsValidator'


/**
 * @function validateAllSteps
 * @description Runs through all the validation functions to check if fully completed
 */


export const validateAllSteps = (createPackage: ICreatePackageDetails) => {
  if (!validateAddSigners(createPackage)) return false
  if (!validatePackageDetails(createPackage)) return false
  if (!validateUploadPackageFiles(createPackage)) return false

  return true
}

/**
 * @function validateAddSigners
 * @description Checks that there are signer(s) added to be submitted
 */

export const validateAddSigners = (createPackage: ICreatePackageDetails) => {
  const signers = createPackage.signersList
  return (signers.length > 0)
}

/**
 * @function validateUploadPackageFiles
 * @description Checks that there are upload file(s) added to be submitted
 */

export const validateUploadPackageFiles = (createPackage: ICreatePackageDetails) => {
  return createPackage?.uploads?.length !== 0
}
