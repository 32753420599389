import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import NotFoundPage from '../shared/pages/NotFoundPage'
import Loading from '../shared/components/loading'
import CookieConsentAlert from '../shared/components/CookieConsentAlert'
import UploadNewPackage from '../eSign/pages/UploadNewPackage'

const Dashboard = lazy(() => import('../orders/pages/Dashboard'))
const Header = lazy(() => import('../shared/components/Header'))
const TransactionDetailsPage = lazy(() => import('../orders/pages/TransactionDetailsPage'))
const LoginPage = lazy(() => import('../shared/pages/LoginPage'))
const CallBackPage = lazy(() => import('../shared/pages/CallBackPage'))
const ViewFilePage = lazy(() => import('../shared/pages/ViewFilePage'))
const Footer = lazy(() => import('../shared/components/Footer'))
const ESignDashboard = lazy(() => import('../eSign/pages/Dashboard'))
const NewPackage = lazy(() => import('../eSign/pages/NewPackage'))
const PackageDetails = lazy(() => import('../eSign/pages/PackageDetails'))

const AppRouter = () => {
  return (
    <Suspense fallback={<Loading />}>
      <CookieConsentAlert />
      <BrowserRouter>
        <Routes>

          <Route
            path='/'
            element={
              <PublicRoute>
                <LoginPage />
                <Footer />
              </PublicRoute>
            }
          />

          <Route
            path='/logout'
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />

          <Route
            path='/login/callback'
            element={
              <PublicRoute>
                <CallBackPage />
              </PublicRoute>
            }
          />

          <Route
            path='/dashboard'
            element={
              <PrivateRoute>
                <Header />
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            path='/transaction/:id'
            element={
              <PrivateRoute>
                <TransactionDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path='/view-file'
            element={
              <PrivateRoute>
                <ViewFilePage />
              </PrivateRoute>
            }
          />

          {/* Esign Routes */}

          <Route
            path='/esign'
            element={
              <PrivateRoute>
                <Header />
                <ESignDashboard />
              </PrivateRoute>
            }
          />

          <Route
            path='/esign/new-package'
            element={
              <PrivateRoute>
                <NewPackage />
              </PrivateRoute>
            }
          />

          <Route
            path='/esign/upload-package'
            element={
              <PrivateRoute>
                <UploadNewPackage />
              </PrivateRoute>
            }
          />

          <Route
            path='/esign/package/:id'
            element={
              <PrivateRoute>
                <Header />
                <PackageDetails />
              </PrivateRoute>
            }
          />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default AppRouter
