import { SortOrder } from '../../../types/ReactTable';
import { DashboardLayout } from '../../constants/dashboardLayout';
import { mergeSessionCategories, sessionCategories } from '../../constants/sessionCategories'
import { SortBy } from '../../constants/sortBy';

/**
 * @function getSessionsByCat
 * @param {array} sessionCategories session categories
 * @returns {array}
 */
export const getSessionsByCat = (sessionCategories: {
    label: string;
    value: string;
}[]) => {
  const sessionsArray:any = []
  for (let i = 0; i < sessionCategories.length; i++) {
    const sesh = JSON.parse(JSON.stringify(sessionCategories[i]))
    sesh.loaded = false
    sesh.checked = true
    sesh.data = null
    sesh.error = null
    sesh.page = 0
    sesh.hasNextPage = true
    sesh.isNextPageLoading = false
    sessionsArray.push(sesh)
  }
  return sessionsArray
}

/**
 * 
 * @param {DashboardLayout} layout Columns or Rows
 * @see DashboardLayout
 * @returns {array}
 */
export const getSessionsByLayout = (layout: DashboardLayout) => {
  return layout === DashboardLayout.Columns
  ? getSessionsByCat(sessionCategories)
  : getSessionsByCat(mergeSessionCategories)
}

export const calcSessCurrentPage = (page?: number) => page ? page : 0
export const calcSessRowsPerPage = (rows?: number) => rows ? rows : 10

/**
 * @function convertSortByValue
 * @description convert separate sort-by and sort-order values into BE property value
 * @param {string} sortByString sort by friendly property name
 * @param {SortOrder} sortOrder desc or asc
 * @returns SortBy property value
 */
export const convertSortByValue = (sortByString?: string, sortOrder?: SortOrder): SortBy => {
  let sortByProp: SortBy
  switch (sortByString) {
    case SortBy.ClosingDate:
      if (sortOrder === 'asc') {
        sortByProp = SortBy.ClosingDate
      } else {
        sortByProp = SortBy.ClosingDate_Desc
      }
      break;
    case SortBy.CreatedDate:
    default:
      if (sortOrder === 'asc') {
        sortByProp = SortBy.CreatedDate
      } else {
        sortByProp = SortBy.CreatedDate_Desc
      }
      break;
  }
  return sortByProp
}