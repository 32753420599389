import { Box, Button, Card, CardContent } from "@mui/material";
import UserInfo from "../../../shared/components/UserInfo";
import { AddRounded, EditRounded } from "@mui/icons-material";

interface IProps {
  firstName: string,
  lastName: string,
  phoneNumber: string,
  onClick?: any,
}



export default function MFAPhoneCard(props: IProps) {
  const { firstName, lastName, onClick, phoneNumber } = props

  return (
    <Card
      variant='outlined'
      sx={{
        padding: '0px'
      }}
    >
      <CardContent
        sx={{
          padding: '0px',
          ':last-child': {
            padding: '0px'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: (theme) => theme.spacing(2)
          }}
        >
          <UserInfo
            primaryText={firstName + ' ' + lastName}
            secondaryColor={(phoneNumber.length !== 0) ? 'primary' : 'error'}
            secondaryText={(phoneNumber.length !== 0) ? phoneNumber : 'NEEDS PHONE'}
            circleText={firstName?.charAt(0) + lastName?.charAt(0)}
          />
         {(phoneNumber?.length > 0) ? (
                <Button onClick={() => onClick()} startIcon={<EditRounded />}>Edit</Button>
              ) : (
                <Button onClick={() => onClick()} startIcon={<AddRounded />}>Add</Button>

              )}

        </Box>

      </CardContent>
    </Card>
  );
}