import { useEffect, useState } from 'react'
import { ILocalDocument } from '../../../types/esign/createPackage'

const useCheckUploading = (uploads: ILocalDocument[]) => {
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
   const itemUploading = uploads.find((item:ILocalDocument) => item.uploading === true)
   setIsUploading((itemUploading) ?  true :false);
  }, [uploads])
  return isUploading;
}
export default useCheckUploading
