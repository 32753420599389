import {
  IPackageDetails,
  IPackage,
  IPackagesResponse
} from '../../../../types/esign/Package'
import { PackageStatusType } from '../../../enums'

export const mockPackageSigner = (
  authenticationStatus = 'None',
  consentStatus = 'None',
  hasSigned = false,
  isLocked = false
) => {
  return {
    id: 'LKJSF-234234243-LKJFSDOI',
    solexPartyId: 12312,
    emailAddress: 'tonytee@gmail.com',
    phoneNumber: '234-444-4444',
    welcomeLink:
      'https://qa.qa.solex.com/welcome/949435dc-1a20-488f-93c0-3a025b5c9392',
    securityQuestions: [
      'What is your zip code?',
      'Date you initiate the transfer?'
    ],
    authenticationStatus: authenticationStatus,
    consentStatus: consentStatus,
    firstName: 'Tony',
    middleName: 'P',
    lastName: 'Tee',
    suffix: '',
    hasSigned: hasSigned,
    isLocked: isLocked,
    authenticationFailedDateTime: "2022-11-10T12:00:00"
  }
}

export const mockPackageData: IPackageDetails = {
  id: 'a44f82c2-62ed-11ee-8364-067682ea0639',
  name: 'name',
  status: 'Open' as PackageStatusType,
  expiredDateUtc: '2023-10-05T19:38:50',
  sessionId: '949435dc-1a20-488f-93c0-3a025b5c9392',
  completedDateUtc: '2023-10-05T19:38:50',
  consentExpirationDateUtc: '2023-10-05T19:38:50',
  isMfaEnabled: false,
  isKbaEnabled: true,
  documents: [],
  signers: [
    mockPackageSigner()
  ],
  creator: {
    emailAddress: 'email@firstam.com',
    firstName: 'Nathan',
    lastName: 'Fielder',
    middleName: null
  }
}



export const mockPackageOpen: IPackage = {
  id: '68a35651-63af-11ee-8364-067682ea0639',
  name: 'Grandma\'s Gift for Jackson',
  clientCode: 'PKTM',
  sessionId: '949435dc-1a20-488f-93c0-3a025b5c9392',
  status: PackageStatusType.Open,
  createdBy: 'lsimpson@firstam.com',
  createdDateUtc: '2023-10-05T19:38:50.0000000',
  expiredDateUtc: '2023-10-15T19:38:50.0000000',
  completedDateUtc: '2023-10-22T19:38:50.0000000',
  signers: [
    {
      solexPartyId: 1,
      firstName: 'Marjorie',
      middleName: 'Bouvier',
      lastName: 'Simpson',
      suffix: '',
      emailAddress: 'msimpson@firstam.com',
      phoneNumber: '512-555-5555',
      hasSigned: false
    },
    {
      solexPartyId: 2,
      firstName: 'Homer',
      middleName: 'J',
      lastName: 'Simpson',
      suffix: '',
      emailAddress: 'hsimpson@firstam.com',
      phoneNumber: '512-555-5555',
      hasSigned: false
    }
  ]
}
export const mockPackageInProgress = Object.assign({}, mockPackageOpen, {
  id: 'abc',
  sessionId: '123',
  status: PackageStatusType.InProgress,
  createdDateUtc: '2023-10-06T19:38:50.0000000',
  expiredDateUtc: '2023-10-16T19:38:50.0000000',
  completedDateUtc: '2023-10-23T19:38:50.0000000',
})

export const mockPackageCanceled = Object.assign({}, mockPackageOpen, {
  id: 'abc',
  sessionId: '123',
  status: PackageStatusType.Cancelled,
  createdDateUtc: '2023-10-07T19:38:50.0000000',
  expiredDateUtc: '2023-10-17T19:38:50.0000000',
  completedDateUtc: '2023-10-24T19:38:50.0000000',
})

export const mockPackageExpired = Object.assign({}, mockPackageOpen, {
  id: 'abc',
  sessionId: '123',
  status: PackageStatusType.Expired,
  createdDateUtc: '2023-10-08T19:38:50.0000000',
  expiredDateUtc: '2023-10-18T19:38:50.0000000',
  completedDateUtc: '2023-10-25T19:38:50.0000000',
})

export const mockPackageComplete = Object.assign({}, mockPackageOpen, {
  id: 'abc',
  sessionId: '123',
  status: PackageStatusType.Complete,
  createdDateUtc: '2023-10-09T19:38:50.0000000',
  expiredDateUtc: '2023-10-19T19:38:50.0000000',
  completedDateUtc: '2023-10-26T19:38:50.0000000',
})

export const mockPackageArchived = Object.assign({}, mockPackageOpen, {
  id: 'abc',
  sessionId: '123',
  status: PackageStatusType.Archived,
  createdDateUtc: '2023-10-10T19:38:50.0000000',
  expiredDateUtc: '2023-10-20T19:38:50.0000000',
  completedDateUtc: '2023-10-27T19:38:50.0000000',
})

export const mockPackageFailed = Object.assign({}, mockPackageOpen, {
  id: 'abc',
  sessionId: '123',
  status: PackageStatusType.Failed,
  createdDateUtc: '2023-10-11T19:38:50.0000000',
  expiredDateUtc: '2023-10-21T19:38:50.0000000',
  completedDateUtc: '2023-10-28T19:38:50.0000000',
})

export const mockPackagesData: IPackagesResponse = {
  result: {
    items: [
      mockPackageOpen,
      mockPackageInProgress,
      mockPackageCanceled,
      mockPackageExpired,
      mockPackageComplete,
      mockPackageArchived,
      mockPackageFailed
    ],
    currentPageNumber: 1,
    pageSize: 15,
    totalItemCount: 7,
    totalPageCount: 1
  },
  validationErrors: null,
  exceptionMessage: null,
  stackTrace: null
}

export const mockedPackagesResponse: IPackagesResponse = {
  result: {
    items: [
      {
        id: "a44f82c2-62ed-11ee-8364-067682ea0639",
        name: "name",
        clientCode: "docu",
        sessionId: "949435dc-1a20-488f-93c0-3a025b5c9392",
        status: PackageStatusType.Open,
        createdBy: "tony@gmail.com",
        createdDateUtc: "2023-10-05T19:38:50",
        expiredDateUtc: "2023-10-05T19:38:50",
        completedDateUtc: "2023-10-05T19:38:50",
        signers: [
          {
            solexPartyId: 1,
            firstName: "Tony",
            middleName: "P",
            lastName: "Tee",
            suffix: "",
            emailAddress: "tonytee@gmail.com",
            phoneNumber: "234-444-4444",
            hasSigned: false
          }
        ]
      },
      {
        id: "68a35651-63af-11ee-8364-067682ea0639",
        name: "name",
        clientCode: "docu",
        sessionId: "949435dc-1a20-488f-93c0-3a025b5c9392",
        status: PackageStatusType.Open,
        createdBy: "dora@gmail.com",
        createdDateUtc: "2023-10-06T18:45:52",
        expiredDateUtc: "2023-10-06T18:45:52",
        completedDateUtc: "2023-10-06T18:45:52",
        signers: [
          {
            solexPartyId: 1,
            firstName: "Dora",
            middleName: "P",
            lastName: "Dee",
            suffix: "",
            emailAddress: "doradee@gmail.com",
            phoneNumber: "234-444-4444",
            hasSigned: false
          }
        ]
      }
    ],
    currentPageNumber: 1,
    pageSize: 15,
    totalItemCount: 2,
    totalPageCount: 1
  },
  validationErrors: null,
  exceptionMessage: null,
  stackTrace: null
}

export const mockedPackagesResponseAsc: IPackagesResponse = {
  result: {
    items: [
      {
        id: "68a35651-63af-11ee-8364-067682ea0639",
        name: "name",
        clientCode: "docu",
        sessionId: "949435dc-1a20-488f-93c0-3a025b5c9392",
        status: PackageStatusType.Open,
        createdBy: "dora@gmail.com",
        createdDateUtc: "2023-10-06T18:45:52",
        expiredDateUtc: "2023-10-06T18:45:52",
        completedDateUtc: "2023-10-06T18:45:52",
        signers: [
          {
            solexPartyId: 1,
            firstName: "Dora",
            middleName: "P",
            lastName: "Dee",
            suffix: "",
            emailAddress: "doradee@gmail.com",
            phoneNumber: "234-444-4444",
            hasSigned: false
          }
        ]
      },
      {
        id: "a44f82c2-62ed-11ee-8364-067682ea0639",
        name: "name",
        clientCode: "docu",
        sessionId: "949435dc-1a20-488f-93c0-3a025b5c9392",
        status: PackageStatusType.Open,
        createdBy: "tony@gmail.com",
        createdDateUtc: "2023-10-05T19:38:50",
        expiredDateUtc: "2023-10-05T19:38:50",
        completedDateUtc: "2023-10-05T19:38:50",
        signers: [
          {
            solexPartyId: 1,
            firstName: "Tony",
            middleName: "P",
            lastName: "Tee",
            suffix: "",
            emailAddress: "tonytee@gmail.com",
            phoneNumber: "234-444-4444",
            hasSigned: false
          }
        ]
      }
    ],
    currentPageNumber: 1,
    pageSize: 15,
    totalItemCount: 2,
    totalPageCount: 1
  },
  validationErrors: null,
  exceptionMessage: null,
  stackTrace: null
}