import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { ExpandMoreRounded, DescriptionOutlined, CheckRounded, AddRounded, DeleteOutlineRounded, PriorityHighRounded, EditOutlined } from "@mui/icons-material"
import { ICreatePackageSignerObject, ITemplateSigner } from "../../../../types/esign/createPackage";
import UserInfo from "../../../shared/components/UserInfo";

interface IProps {
  signers: ITemplateSigner[],
  templateName: string,
  addSigner?: any,
  editSigner?: any,
  removeSigner?: any,
  completed: boolean,
  readonly?: boolean
}

export default function TemplateSigners(props: IProps) {
  const { readonly, signers, templateName, addSigner, editSigner, removeSigner, completed } = props

  const handleAddSigner = (event: React.MouseEvent<HTMLElement>, signer: ICreatePackageSignerObject) => {
    if (addSigner && signer) {
      addSigner(event, signer)
    }
  }

  const handleEditSigner = (signer: any) => {
    if (removeSigner && signer) {
      editSigner(signer)
    }
  }

  const handleRemoveSigner = (signer: any) => {
    if (removeSigner && signer) {
      removeSigner(signer)
    }
  }

  const signerRow = (signer: ITemplateSigner, index: number) => {
    if (!signer?.signer?.signerEmail && readonly) { return null }
    if (!signer?.signer?.signerEmail || !signer.values) {
      return (
        <ListItem
          key={index}
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            '&:last-child': {
              borderBottom: 'none'
            },
          }}
          secondaryAction={
            <Box
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
                color: (theme) => (signer?.signer?.isSignerRequired) ? theme.palette.error.main : null
              }}
            >
              {signer?.signer?.isSignerRequired ? 'REQUIRED' : 'OPTIONAL'}
            </Box>
          }
          disablePadding
        >
          <ListItemButton onClick={(e) => handleAddSigner(e, signer?.signer)}>
            <ListItemIcon sx={{ minWidth: '0px', marginRight: '5px' }}>
              <AddRounded />
            </ListItemIcon>
            <ListItemText primary={signer?.signer?.signerDisplayName} />
          </ListItemButton>
        </ListItem>
      )
    } else {
      return (
        <Box
          key={index}
          sx={{
            padding: '5px 0px',
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            '&:last-child': {
              borderBottom: 'none'
            },
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <UserInfo
            primaryText={signer?.values?.firstName + ' ' + signer?.values?.lastName}
            secondaryText={signer?.signer?.signerDisplayName}
            circleText={(signer?.values?.firstName) ? signer?.values?.firstName.charAt(0) + signer?.values?.lastName?.charAt(0) : ''}
          />
          {!readonly &&
            <Box>
              <IconButton onClick={() => handleEditSigner(signer?.values)}><EditOutlined /></IconButton>
              <IconButton onClick={() => handleRemoveSigner(signer?.signer)}><DeleteOutlineRounded /></IconButton>
            </Box>
          }

        </Box>
      )
    }
  }
  return (
    <Box sx={{ padding: '5px 0px' }}>
      <Accordion
        disableGutters
        elevation={0}
        defaultExpanded
        sx={{ border: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Typography
            sx={{ display: 'flex', alignItems: 'center', lineHeight: '0' }}>

            {!readonly &&
              <>
                {completed ? (
                  <CheckRounded color='success' />
                ) : (
                  <PriorityHighRounded color='error' />
                )}
              </>

            }
            <DescriptionOutlined />
            {templateName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '5px' }}>
          <List>
            {signers.map((signer: any, index: number) => {
              return signerRow(signer, index)
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
