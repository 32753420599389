
import { Button, Grid, Typography } from '@mui/material'

/**
 * @component StepperHeader 
 * @description Component that has a section title bottom border & button.
 */

interface IProps {
  title: string
  buttonText?: string
  buttonClicked?: () => void
  buttonStartIcon?: React.ReactNode
  buttonEndIcon?: React.ReactNode
}

export default function StepperHeader(props: IProps) {
  const {
    title,
    buttonText,
    buttonClicked,
    buttonStartIcon,
    buttonEndIcon
  } = props


  return (
    <Grid
      container
      paddingTop={1}
      paddingBottom={1}
      marginBottom={(theme) => theme.spacing(2)}
      borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      justifyContent={'space-between'}
    >
      <Grid item><Typography variant='h6'>{title}</Typography></Grid>
      <Grid item>
        {buttonText &&
          <Button
            color='primary'
            startIcon={(buttonStartIcon) ? buttonStartIcon : null}
            endIcon={(buttonEndIcon) ? buttonEndIcon : null}
            onClick={() => (buttonClicked) ? buttonClicked() : null}
          >
            {buttonText}
          </Button>
        }
      </Grid>
    </Grid>
  )
}
