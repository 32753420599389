import Loading from '../shared/components/loading'
import RefreshingTokenOverlay from '../orders/components/RefreshingTokenOverlay'
import { RootState } from '../redux-store/store';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom'
import Footer from '../shared/components/Footer';


const PrivateRoute = ({children = {}}) => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const refreshingToken = useSelector((state: RootState) => state.auth.refreshingToken);
  
  const url = new URL(document.location as any)
  const isOrderUrl = (url.pathname).includes('transaction')

  if (isOrderUrl && !isLoggedIn) {
    window.localStorage.setItem('orderId', url.pathname)
  }

  return (
    <div>
      {loading ? (
        <Loading text='Loading...' />
      ) : (
        <>
          {refreshingToken &&
            <RefreshingTokenOverlay />
          }
            {(isLoggedIn) ? (
              <>
              {children}
              <Footer footerHeight='45px' />
             </>
            ) : (
              <Navigate to="/" />
            )}
        </>
      )}
    </div>
  )
}


export default PrivateRoute
