import { Grow, Box, IconButton } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import './_base.scss'
import { IAlert } from '../../../../types/Alert';


function Alert(props: IAlert) {
  const getClassName = () => {
    let classNameValue = 'simple-alert'
    classNameValue += props.type ? ' ' + props.type : ''
    classNameValue += props.bold ? '  bold' : ''
    classNameValue += props.elevated ? ' elevated' : ''
    return classNameValue
  }

  return (
    <Grow in>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        className={getClassName()}
        data-testid={`${props.type}`}
        role='alert'>
        {props.icon && (
          <Box flexGrow={0} className="simple-alert-icon">
            {props.icon}
          </Box>
        )}
        <Box flexGrow={1} className="simple-alert-text">
          {props.text}
        </Box>
        {props.onClose && (
          <Box flexGrow={0}>
            <IconButton
              color="inherit"
              size="small"
              className="simple-alert-close"
              onClick={props.onClose}
              component="button"
              data-testid={`close-icon`}>
              <CloseRounded fontSize="inherit" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Grow>
  )
}

export default Alert
