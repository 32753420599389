
import { styled, keyframes } from "@mui/system";

const GraphicContainer = styled('div')(() => ({
  width: '100%',
  maxWidth: '300px',
  position: 'relative'
}));

const GraphicSVG = styled('svg')(() => ({
  width: '100%',
  height: 'auto'
}));

const ArrowCircle = styled('path')(() => ({
  width: '100%',
  height: 'auto',
  animation:`${rotateForward} 6s linear infinite`,
  transformOrigin:'50%'
  
}));

const ArrowCircle2 = styled('path')(() => ({
  width: '100%',
  height: 'auto',
  animation:`${rotateReverse} 8s linear infinite`,
  transformOrigin:'50%'
}));

const rotateForward = keyframes`   
100% {
  transform: rotate(360deg);
  }
`;
const rotateReverse = keyframes`   
100% {
  transform: rotate(-360deg);
  }
`;

export default function UploadingGraphic() {
  return (
    <GraphicContainer>
      <GraphicSVG width="206" height="210" overflow='visible' viewBox="0 0 206 210" fill="none">
        <ArrowCircle d="M95.1007 36.167L89.731 34.0458L90.5789 39.7567L95.1007 36.167ZM110.899 173.834L116.268 175.956L115.42 170.245L110.899 173.834ZM34.666 105.001C34.666 71.2227 58.853 43.1338 90.7396 37.3197L90.5602 36.3359C58.2023 42.236 33.666 70.7356 33.666 105.001H34.666ZM110.842 36.6637C144.885 40.5786 171.333 69.6788 171.333 105.001H172.333C172.333 69.1701 145.503 39.6431 110.956 35.6703L110.842 36.6637ZM95.1579 173.338C61.1143 169.423 34.666 140.323 34.666 105.001H33.666C33.666 140.831 60.4959 170.358 95.0436 174.331L95.1579 173.338ZM171.333 105.001C171.333 138.779 147.146 166.868 115.26 172.682L115.439 173.665C147.797 167.765 172.333 139.266 172.333 105.001H171.333Z" fill="#D8D8D8" />
        <ArrowCircle2 d="M91.2479 2.58887L85.9013 0.409945L86.6876 6.12966L91.2479 2.58887ZM114.752 207.411L120.099 209.59L119.312 203.87L114.752 207.411ZM1.08887 105C1.08887 53.8699 38.2507 11.4794 86.865 3.69595L86.7069 2.70853C37.6105 10.5692 0.0888672 53.3756 0.0888672 105H1.08887ZM114.695 3.08559C165.468 8.92435 204.911 52.3238 204.911 105H205.911C205.911 51.8151 166.086 7.98887 114.809 2.09214L114.695 3.08559ZM91.305 206.914C40.5318 201.076 1.08887 157.676 1.08887 105H0.0888672C0.0888672 158.185 39.9135 202.011 91.1908 207.908L91.305 206.914ZM204.911 105C204.911 156.13 167.749 198.52 119.135 206.304L119.293 207.291C168.389 199.431 205.911 156.624 205.911 105H204.911Z" fill="#D8D8D8" />
        <path d="M82.016 133.079C76.9017 133.079 72.5009 131.236 68.8138 127.549C65.1267 123.862 63.2832 119.461 63.2832 114.347C63.2832 109.708 64.7551 105.619 67.6988 102.081C70.6425 98.5426 74.4337 96.4166 79.0723 95.703C80.2617 89.9345 83.0567 85.2215 87.4574 81.5642C91.8582 77.9068 96.9428 76.0781 102.711 76.0781C109.431 76.0781 115.066 78.5015 119.615 83.3482C124.165 88.195 126.44 94.0081 126.44 100.788V102.928C130.721 102.81 134.349 104.192 137.322 107.076C140.296 109.961 141.783 113.603 141.783 118.004C141.783 122.107 140.296 125.646 137.322 128.619C134.349 131.593 130.811 133.079 126.707 133.079H105.209C103.782 133.079 102.533 132.544 101.462 131.474C100.392 130.403 99.8568 129.154 99.8568 127.727V104.713L92.4529 112.116L88.6171 108.281L102.533 94.3649L116.449 108.281L112.613 112.116L105.209 104.713V127.727H126.707C129.383 127.727 131.673 126.776 133.576 124.873C135.479 122.97 136.43 120.68 136.43 118.004C136.43 115.328 135.479 113.038 133.576 111.135C131.673 109.232 129.383 108.281 126.707 108.281H121.087V100.788C121.087 95.4948 119.288 90.9454 115.69 87.1394C112.093 83.3334 107.647 81.4304 102.354 81.4304C97.0617 81.4304 92.6015 83.3334 88.9739 87.1394C85.3463 90.9454 83.5325 95.4948 83.5325 100.788H81.8376C78.1505 100.788 75.0284 102.081 72.4712 104.668C69.914 107.255 68.6354 110.451 68.6354 114.257C68.6354 117.944 69.9416 121.111 72.5541 123.758C75.1664 126.404 78.3204 127.727 82.016 127.727H94.5045V133.079H82.016Z" fill="#143B6B" />
      </GraphicSVG>
    </GraphicContainer>
  )
}

