export enum ESignRoles {
  Basic      = 'sxvs.esignbasic',
  Supervisor = 'sxvs.esignsupervisor',
  Admin      = 'sxvs.esignadmin'
}

export enum SignerConsentStatus {
  None       = 'None',
  Consented = 'Consented',
  Rejected  = 'Rejected'
}

export enum SignerAuthenticationStatus {
  None       = 'None',
  Success = 'Success',
  Failed  = 'Failed'
}

export enum APIDocumentUploadStatus {
  Encrypted = 'Encrypted',
  ScanFailure = 'ScanFailure',
  EncryptionFailure  = 'EncryptionFailure',
  Infected = 'Infected'
}

export enum DocumentUploadStatus {
  Complete = 'Complete',
  Uploading = 'Uploading...',
  Failed  = 'Failed'
}
