import { Typography } from '@mui/material'

export const TermsOfUse = () => {
  return (
    <div>
      <Typography sx={{ fontSize: '14px' }}>
        Please read these Terms of Use (“Terms”, “Terms of Use”) carefully before using Solex©, and/or Solex© eDelivery Methods (the “Services”). Your access to, and use of, the Services is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Services. By accessing or using the Services, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access the Services.
      </Typography>
      <Typography variant='h6' sx={{ color: 'primary.main', marginTop: 2 }}>
        Termination
      </Typography>
      <Typography sx={{ fontSize: '14px' }}>
        We may terminate or suspend access to our Services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
      </Typography>
      <Typography variant='h6' sx={{ color: 'primary.main', marginTop: 3 }}>
        Intellectual Property
      </Typography>
      <Typography sx={{ fontSize: '14px' }}>
        All copyright, trademarks, design rights, patents, and other intellectual property rights (both registered and unregistered) in and on the Services and content belong to us and/or third parties. We reserve all of our rights in the Services and content. Nothing in the Terms grants you a right or license to use any trademark, design right, or copyright owned or controlled by us or any other third party except as expressly provided in the Terms.
      </Typography>
      <Typography variant='h6' sx={{ color: 'primary.main', marginTop: 3 }}>
        Links to Other Websites
      </Typography>
      <Typography sx={{ fontSize: '14px' }}>
        Our Services may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services. We strongly advise you to read the Terms and conditions and privacy policies of any third-party websites or services that you visit.
      </Typography>
      <Typography variant='h6' sx={{ color: 'primary.main', marginTop: 3 }}>
        Governing Law
      </Typography>
      <Typography sx={{ fontSize: '14px' }}>
        These Terms shall be governed and construed in accordance with the laws of the State of Idaho, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Services, and supersede and replace any prior agreements we might have between us regarding the Services.
      </Typography>
      <Typography variant='h6' sx={{ color: 'primary.main', marginTop: 3 }}>
        Changes
      </Typography>
      <Typography sx={{ fontSize: '14px' }}>
        We may, at any time, and at our sole discretion, modify these Terms of Use, including our Privacy Policy, with notice to the User. Any such modification will be effective immediately upon public posting. Your continued use of our Services and this Site following any such modification constitutes your acceptance of these modified Terms.
      </Typography>
      <Typography variant='h6' sx={{ color: 'primary.main', marginTop: 3 }}>
        Performance
      </Typography>
      <Typography sx={{ fontSize: '14px' }}>
        You understand that the Service may not perform as intended at all times. You agree that we are not responsible for any interruption in, or delay of the transmission of the provided documents due to communication line failure, system failure, or other circumstances beyond our control. The information displayed on the documents is based on information previously provided by you. If you believe you have discovered an error or discrepancy in the documents presented by the Service, you agree to contact us immediately to inform us of such error or discrepancy.
      </Typography>
    </div>
  )
}

export default TermsOfUse
