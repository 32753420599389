import { axiosEsignInstance } from '../../shared/utils/axios'
import axios from 'axios'
/**
 *
 * @param {string} query string of query params to be used in API path
 * example API path: /sessions/search?TermTypes=LoanNumber&Terms=jb_te&page=0&PageCount=10
 * @param cancelToken 
 * @param {object} config localConfig 
 * @returns {Promise} array of sessions
 */
export const getTemplateCategories = (config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url:string = config.esignApiUrl + '/Template/categories'
    axiosEsignInstance.get(url)
      .then((response) => {
        resolve(response.data.result)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled...
          // resolve('')
        } else {
          reject(error)
        }
      })
  })
}
/**
 *
 * @param {string} id category id used to get templates
 * example API path: /sessions/search?TermTypes=LoanNumber&Terms=jb_te&page=0&PageCount=10
 * @param cancelToken 
 * @param {object} config localConfig 
 * @returns {Promise} array of sessions
 */
export const getCategoryTemplates = (id: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url: string = config.esignApiUrl + '/Template?categoryId=' + id
    axiosEsignInstance.get(url)
      .then((response) => {
        resolve(response.data.result)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled...
          // resolve('')
        } else {
          reject(error)
        }
      })
  })
}

export const cancelPackage = (packageId: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url: string = config.esignApiUrl + '/package/cancel/' + packageId
    axiosEsignInstance.put(url)
      .then((response) => {
        resolve(response.data.result)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled...
          resolve('')
        } else {
          reject(error)
        }
      })
  })
}