import { ILocalDocument } from '../../../types/esign/createPackage'
import { axiosEsignInstance } from '../../shared/utils/axios'
import axios, { AxiosRequestConfig } from 'axios'
import { fileChecksum } from '../../shared/utils/fileChecksum'
import { parseParams } from '../../shared/utils/helpers'
/**
 *
 * example API path: /DocumentUpload/document-upload-link
 * @param {object} UploadId upload id used for s3 bucket folder
 * @param {object} documents array of local files to be uploaded
 * @param {object} config localConfig
 * @returns {Promise} Returns an object with preSignedUrls array & UploadId if not provided to start
 */
export const beginUploadDocuments = (UploadId: string | undefined, documents: File[], config: any) => {
  return new Promise((resolve, reject) => {
    (async function() {
      const url = config.esignApiUrl + '/DocumentUpload/document-upload-link'
      const md5Array: string[] = []
      const documentNames: string[] =[]

      for (let i = 0; i < documents.length; i++) {
        const hex:any = await fileChecksum(documents[i])
        documentNames.push(documents[i].name)
        md5Array.push(hex)
      } 

      const payload:any = {
        DocumentNames: documentNames
      }

      // if UploadId exists add to object
      if (UploadId) {
        payload['UploadId'] = UploadId
      }

      axiosEsignInstance.get(url, {
        params: payload,
        paramsSerializer:  params => parseParams(params) 
      })
      .then((response) => {
        resolve({
          preSignedUrls: response.data.result.documents,
          newUploadId: response.data.result.uploadId
        })
      })
      .catch((error) => {
        reject((error?.response?.data?.Message) ? error.response.data.Message : error.message)
      })
    })()
  })
}
/**
 *
 * @param {object} url this must be a presigned url received by beginUploadDocuments api
 * @param {object} file local file to be uploaded
 * @returns {Promise} Returns 200 on success
 */
export const uploadFileToS3 = (url: string, file: ILocalDocument['file']) => {
  return new Promise((resolve, reject) => {
    
    const axiosConfig: AxiosRequestConfig = {
      method: 'PUT',
      url: url,
      headers: {
        'Content-Type': 'application/pdf'
      },
      data: file
    }
    axios(axiosConfig).then((res) => {
      resolve(res)
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 *
 * example API path: /DocumentUpload/{UploadId}/status
 * @param {object} UploadId upload id used for s3 bucket folder
 * @param {object} documents array of local files to have status retrieved for
 * @param {object} config localConfig
 * @returns {Promise} Returns an object with status of documents requested
 */
export const getDocumentStatus = (UploadId: string | undefined, documentNames: string[], config: any) => {
  return new Promise((resolve, reject) => {
    (async function() {
      const url = config.esignApiUrl + '/DocumentUpload/' + UploadId + '/status'

      const payload:any = {
        DocumentNames: documentNames
      }

      axiosEsignInstance.get(url, {
        params: payload,
        paramsSerializer:  params => parseParams(params) 
      })
      .then((response) => {
        resolve({
          documentStatuses: response.data.result.documentStatuses,
        })
      })
      .catch((error) => {
        reject((error?.response?.data?.Message) ? error.response.data.Message : error.message)
      })
    })()
  })
}