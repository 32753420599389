
import { EmailOutlined, PhoneOutlined } from '@mui/icons-material';
import { Avatar, Typography, Box, Button } from '@mui/material';

/**
 * 
 * @description User info component that displays a users various information
 * 
 */

interface IProps {
  primaryText: string,
  secondaryText?: string,
  primaryColor?: string,
  secondaryColor?: string,
  circleText?: string,
  emailAddress?: string,
  onEmailClicked?: () => void,
  phoneNumber?: string
  onPhoneNumberClicked?: () => void,
}


export default function UserInfo(props: IProps) {
  const {
    primaryText,
    secondaryText,
    primaryColor,
    secondaryColor,
    circleText,
    emailAddress,
    onEmailClicked,
    phoneNumber,
    onPhoneNumberClicked
  } = props

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      {circleText &&
        <Avatar sx={{ marginLeft: (theme) => theme.spacing(1.5), marginRight: (theme) => theme.spacing(1.5), fontSize: '16px', width: 35, height: 35 }}>{circleText}</Avatar>
      }

      <Box
        sx={{ display: 'flex', flexDirection: 'column' }}
      >

        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'  }}
        >
          <Typography color={(primaryColor) ? primaryColor : undefined} variant='subtitle1'>{primaryText}</Typography>

          {secondaryText &&
            <Typography color={(secondaryColor) ? secondaryColor : undefined} variant='caption'  marginLeft= '10px' lineHeight={0} > {secondaryText}</Typography>
          }
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          {emailAddress &&
            <Button
              sx={{
                color: 'grey'
              }}
              startIcon={<EmailOutlined />}
              size='small'
              onClick={() => (onEmailClicked) ? onEmailClicked() : null}>
              {emailAddress ? emailAddress : ''}
            </Button>
          }
          {phoneNumber &&
            <Button
              sx={{
                color: 'grey'
              }}
              startIcon={<PhoneOutlined />}
              size='small'
              onClick={() => (onPhoneNumberClicked) ? onPhoneNumberClicked() : null}>
              {phoneNumber ? phoneNumber : ''}
            </Button>
          }
        </Box>
      </Box>
    </Box>
  )
}
