import { useState } from 'react'
import {Box, Button, Link, Paper, Typography } from '@mui/material'
import { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { useCookies } from 'react-cookie';

const Container = styled('div')(() => ({
  position: 'fixed',
  width: '100%',
  maxWidth: '1200px',
  zIndex: '99999999999999999',
  left:'50%',
  bottom:'30px',
  transform:'translateX(-50%)'
}));

const ResponsiveBox = styled('div')(({ theme }: any) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export default function CookieConsentAlert() {
  const [show, setShow] = useState(false);
  const [cookies, setCookie] = useCookies(['fa_cpp']);

  useEffect(() => {
    const cookiePrivacyPolicy = cookies['fa_cpp']
    if(!cookiePrivacyPolicy) {
      setShow(true)
      const appBody = document.getElementById('app')
      if (appBody) {
        appBody.style.paddingBottom = '200px'
      }
    }
  })

  const onAccept = () => {
    const date = new Date()
    date.setDate(date.getDate() + 30)
    setCookie('fa_cpp', 'accepted', { path: '/', expires:date})
    setShow(false)
  }

  if (!show) {
    return null
  }

  return (
    <Container>
      <Paper elevation={8}>
        <ResponsiveBox>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex:'3', padding:'15px'}}>
            <Typography variant='h6'>Privacy Notice</Typography>
            <Typography variant='body1'>This website uses cookies and similar technologies to manage your sessions, manage content, and improve your website experience. To learn more about these technologies, your options, and about other categories of personal information we collect through this website and how we may use it, please see our <Link target="_blank" href="https://www.firstam.com/privacy-policy/#type-of-information"><strong>privacy policy</strong></Link>.</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent:'center', flex:'1', padding:'15px' }}>
            <Button onClick={() => onAccept()} variant='contained' color='primary'>I UNDERSTAND</Button>
          </Box>
        </ResponsiveBox>
      </Paper>
    </Container>
  )
}
