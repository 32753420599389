import React, { useCallback, useEffect } from 'react'
import { Column, ColumnInterface, useTable } from 'react-table'
import { DndProvider, useDrag, useDrop, DragSourceMonitor } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { DeleteOutline, DragIndicatorRounded, ErrorOutlineOutlined, CloseOutlined } from '@mui/icons-material'

/**
 * @component DragAndDropList 
 * @description A drag & drop list allowing reordering, removing and uploading/error
 */

interface IPropsTable {
  columns: Column<Record<string, any>>[]
  data: Record<string, any>[]
  cellAction: (type: string, data: Record<string, any> | []) => void
  updateOrder: (data: Record<string, any>[]) => void
  showLoader: boolean
  allowRemove: boolean
  readonly?: boolean
}

const DNDTable = ({ columns, data, cellAction, updateOrder, allowRemove, showLoader, readonly }: IPropsTable) => {
  const [records, setRecords] = React.useState(data)

  const getRowId = React.useCallback((row: any) => {
    return row.id
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    data: records,
    columns,
    getRowId,
  })

  const moveRow = useCallback((dragIndex: number, hoverIndex: number, records: any) => {
    setRecords(() =>
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, records[dragIndex]],
        ],
      }),
    )
  }, [])

  const droppedRow = () => {
    updateOrder(records)
  }

  useEffect(() => {
    setRecords(data)
  }, [data])

  return (
    <DndProvider backend={HTML5Backend}>
      <TableContainer>
        <Table size="small" aria-label="simple table" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                {!readonly &&
                  <TableCell align='left'></TableCell>
                }
                <TableCell align='left'>Order</TableCell>
                {headerGroup.headers.map((column: any, index2: number) => (
                  <TableCell align='left' key={index2}>{column.render('Header')}</TableCell>
                ))}
                <TableCell align='right'></TableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row: any, index: any) => {
              let preppedRow: any = prepareRow(row)
              if (!preppedRow) {
                preppedRow = <Row
                  key={index}
                  index={index}
                  row={row}
                  allowRemove={allowRemove}
                  showLoader={showLoader}
                  droppedRow={droppedRow}
                  readonly={readonly}
                  moveRow={(dragIndex: number, hoverIndex: number) => moveRow(dragIndex, hoverIndex, records)}
                  goCellAction={cellAction}
                  {...row.getRowProps()}
                />
              }
              return preppedRow
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </DndProvider>
  )
}

const DND_ITEM_TYPE = 'row'

interface IPropsRow {
  row: any
  index: any
  moveRow: any
  droppedRow: any
  goCellAction: any
  showLoader: boolean
  allowRemove: boolean
  readonly: boolean
}

const Row = ({ row, index, moveRow, droppedRow, goCellAction, showLoader, allowRemove, readonly }: IPropsRow) => {
  const dropRef: any = React.useRef(null)
  const dragRef: any = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    drop: droppedRow,
    hover(item: any, monitor: any) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview]: any = useDrag({
    type: DND_ITEM_TYPE,
    item: () => ({ index }),
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  } as any)

  const opacity = isDragging ? 0 : 1

  const getStatusStyle = () => {
    if (row.values?.status === 'Complete') {
      return { color: 'green' };
    } else if (row.values?.status === 'Failed') {
      return { color: 'red' };
    }
    return { color: 'inherit' }
  };

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <TableRow ref={dropRef} sx={{ opacity: opacity }}>
      {!readonly &&
        <TableCell ref={dragRef} width={50}>
          {showLoader && row.values?.loading &&
            <CircularProgress data-testid='DocumentUploading' size={30} />
          }

          {showLoader && row.values?.error &&
            <Tooltip title={(row.values?.errorMessage) ? row.values?.errorMessage : 'Error Uploading'}>
              <IconButton data-testid='DNDError' size='small'><ErrorOutlineOutlined color='error' /></IconButton>
            </Tooltip>
          }

          {!row.values?.loading && !row.values?.error &&
            <IconButton size='small'><DragIndicatorRounded /></IconButton>
          }
        </TableCell>
      }

      {!row.values?.loading &&
        <TableCell width={50} align="center">{(index + 1)}</TableCell>
      }
      {showLoader && row.values?.loading &&
        <TableCell width={50} align="center">-</TableCell>
      }
      {row.values?.fileSize &&
        <TableCell width={400} align="left" style={{ color: getStatusStyle().color === 'red' ? 'red' : 'inherit' }}>
          <div>{row.values?.fileName}</div>
          <div>{(row.values?.fileSize / 1000).toFixed(2)} kb  &bull; <span style={getStatusStyle()}>{row.values?.status}</span> {row.values?.error && <span> - {row.values?.errorMessage}</span>}</div>
        </TableCell>
      }
      {!row.values?.fileSize &&
        row.cells.map((cell: any, index2: number) => {
          return <TableCell key={index2}>{cell.render('Cell')}</TableCell>
        })
      }

      <TableCell key={index} align='right' width={50} colSpan={6}>
        {!readonly && (allowRemove && (!showLoader || showLoader && !row.values?.loading)) &&
          <IconButton data-testid='DNDDeleteButton' size='small' onClick={() => goCellAction('removeItem', index)}><DeleteOutline /></IconButton>
        }
        {!readonly && (showLoader && row.values?.loading) &&
          <IconButton data-testid='DNDDeleteButton' size='small' onClick={() => goCellAction('removeItem', index)}><CloseOutlined /></IconButton>
        }

      </TableCell>
    </TableRow>
  )
}

export interface DNDColumnData {
  id: string | number
  loading?: boolean
  error?: boolean
  [key: string]: any
}

interface IProps {
  columns: Column<Record<string, any>>[]
  data: DNDColumnData[]
  cellAction?: any
  updateOrder?: any
  allowRemove?: boolean
  showLoader?: boolean
  readonly?: boolean
}



const DragAndDropList = ({ columns, data, cellAction, updateOrder, showLoader = false, allowRemove = false, readonly = false }: IProps) => {
  const [list, setList] = React.useState<any>([])
  const [allColumns, setAllColumns] = React.useState<any>([])

  useEffect(() => {
    const fullColumnsArray = [...columns]
    // Add loading & error columns if no custom accessor defined
    if (!(fullColumnsArray.find((item: ColumnInterface) => item.id === 'loading'))) {
      fullColumnsArray.push({ id: 'loading', accessor: 'loading' })
    }
    if (!(fullColumnsArray.find((item: ColumnInterface) => item.id === 'error'))) {
      fullColumnsArray.push({ id: 'error', accessor: 'error' })
    }
    setList(data)
    setAllColumns(fullColumnsArray)
  }, [data])

  return (
    <DNDTable readonly={readonly} allowRemove={allowRemove} showLoader={showLoader} columns={allColumns} data={list} cellAction={cellAction} updateOrder={updateOrder} />
  )
}

export default DragAndDropList