import { Alert, Box, Button, Typography } from "@mui/material";
import { ICreatePackageDetails, ILocalDocument } from "../../../../types/esign/createPackage";
import DocumentUploader from "../../../shared/components/DocumentUploader";
import { StepperActions } from "../../../enums";
import DragAndDropList from "../../../shared/components/DragAndDropList";
import StepperHeader from "../../../shared/components/StepperHeader";
import { validateUploadPackageFiles } from "../../utils/uploadPackageStepValidator";
import DisableContainer from "../../../shared/components/DisableContainer";
import useCheckUploading from "../../hooks/useCheckUploading";
import { useState } from "react";
import { InfoOutlined } from "@mui/icons-material";

/**
 * @component UPUploadDocuments (UP stands for Upload Package)
 * @description eSign component for uploading and arranging documents for the upload package flow
 */


interface IProps {
  backStep?: () => void;
  nextStep?: () => void;
  data?: ICreatePackageDetails;
  action?: (type: string, data?: any) => void;
  readonly?: boolean
}

export default function UPUploadDocuments(props: IProps) {
  const { backStep, nextStep, action, data } = props
  const [errorSelectingFile, setErrorSelectingFile] = useState<string | null>(null);

  const disableUploading = useCheckUploading((data?.uploads) ? data.uploads : [])

  const handleBack = () => {
    if (backStep) {
      backStep()
    }
  }

  const handleNext = () => {
    if (nextStep) {
      nextStep()
    }
  }

  const handleUploadFiles = (incomingFiles: File[]) => {
    setErrorSelectingFile(null)

    if (incomingFiles.length === 0)
      return
    const filesDetails: ILocalDocument[] = []
    const localFiles: File[] = []
    const alreadyUploaded: string[] = []

    for (let i = 0; i < incomingFiles.length; i++) {
      const localDocumentDetails: ILocalDocument = {
        id: incomingFiles[i].name,
        uploading: true,
        errorUploading: false,
        errorMessage: null,
        name: incomingFiles[i].name,
        fileSize: incomingFiles[i].size
      }
      const localFile: File = incomingFiles[i]

      const alreadyAdded = data?.uploads.find((existingFile: any) => {
        return existingFile.name === localFile.name
      })

      if (alreadyAdded) {
        alreadyUploaded.push(incomingFiles[i].name)
      } else {
        filesDetails.push(localDocumentDetails)
        localFiles.push(localFile)
      }
    }

    if (alreadyUploaded.length !== 0) {
      setErrorSelectingFile('These files have already been added: ' + alreadyUploaded.join(', '))
    }

    if (action) {
      action(StepperActions.UploadFile, { files: localFiles, filesDetails: filesDetails })
    }
  }

  const handleCellAction = (cellAction: string, index: number) => {
    if (cellAction === 'removeItem') {
      setErrorSelectingFile(null)
      if (action) {
        action(StepperActions.RemoveFile, index)
      }
    }
  }

  const handleReOrderList = (updatedList: ILocalDocument[]) => {
    if (action) {
      action(StepperActions.ReorderList, updatedList)
    }
  }

  return (
    <Box sx={{ padding: (theme) => theme.spacing(2) }}>

      <Box>
        <StepperHeader
          title='Upload Documents'
        />
        <Box
          display='flex'
          flexDirection='column'
        >
          <Box
            flex={1}
            width='100%'
            display='flex'
            flexDirection='column'
            marginBottom={'10px'}
          >
            <DisableContainer
              disable={disableUploading}
            >
              <DocumentUploader
                maxSize={70}
                docType='.pdf'
                startUploadFiles={(files) => handleUploadFiles(files)}
                multiple={true}
              />
              {errorSelectingFile &&
                <Alert color='info' icon={<InfoOutlined />}>{errorSelectingFile}</Alert>}
            </DisableContainer>

          </Box>
          <Box
            flex={1}
            width='100%'
            display='flex'
            flexDirection='column'
          >
            {data && data?.uploads && data?.uploads.length > 0 &&
              <Box
                border={(theme) => `solid 1px ${theme.palette.divider}`}
                flex={1}
              >
                <DragAndDropList
                  allowRemove
                  showLoader={true}
                  updateOrder={handleReOrderList}
                  cellAction={handleCellAction}
                  columns={[
                    { id: 'fileInfo', Header: 'File Info'},
                    { id: 'error', accessor: (item: any) => item?.errorUploading },
                    { id: 'loading', accessor: (item: any) => item?.uploading },
                    { id: 'status', accessor: (item: any) => item?.status },
                    { id: 'errorMessage', accessor: (item: any) => item?.errorMessage },
                    { id: 'fileName', accessor: (item: any) => item?.name },
                    { id: 'fileSize', accessor: (item: any) => item?.fileSize },
                  ]}
                  data={(data?.uploads) ? data.uploads : []}
                />
              </Box>
            }
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: (theme) => theme.spacing(2)
        }}
      >
        <Button variant='outlined' color='primary' onClick={() => handleBack()}>Back</Button>
        {data?.allUploadsComplete && (
          <div style={{ flexGrow: 1, textAlign: 'right', marginRight: '10px', marginTop: '5px' }}>
            <Typography>{(data?.uploads) ? data?.uploads.length : 0} Documents will be added to your package</Typography>
          </div>
        )}
        <Button variant='contained' color='primary' disabled={(data && data?.uploads) ? (!validateUploadPackageFiles(data) || disableUploading) : false} onClick={() => handleNext()}>Next</Button>
      </Box>
    </Box>
  )
}
