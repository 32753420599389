import { Box, Button, Card, CardContent, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import UserInfo from "../../../shared/components/UserInfo";
import { AddRounded, EditRounded } from "@mui/icons-material";
import SecretAnswer from "../SecretAnswer";

interface IProps {
  firstName: string,
  lastName: string,
  questions: any,
  onClick?: any,
  readonly?: boolean
}



export default function KBASignerCard(props: IProps) {
  const { firstName, lastName, onClick, questions, readonly } = props

  return (
    <Card
      variant='outlined'
      sx={{
        padding: '0px'
      }}
    >
      <CardContent
        sx={{
          padding: '0px',
          ':last-child': {
            padding: '0px'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: (theme) => theme.spacing(2)
          }}
        >
          <UserInfo
            primaryText={firstName + ' ' + lastName}
            circleText={firstName?.charAt(0) + lastName?.charAt(0)}
          />
          {!readonly &&
            <>
              {(questions?.length > 0) ? (
                <Button onClick={() => onClick()} startIcon={<EditRounded />}>Edit</Button>
              ) : (
                <Button onClick={() => onClick()} startIcon={<AddRounded />}>Add</Button>

              )}
            </>
          }

        </Box>

        {(readonly && questions.length === 0) &&
          <Typography
            variant='body1'
            sx={{
              borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              padding: (theme) => theme.spacing(1.5)
            }}
          >
            No Questions Set
          </Typography>
        }
        {(questions?.length !== 0) &&
          <List
            sx={{
              padding: '0px',
              borderTop: (theme) => `1px solid ${theme.palette.divider}`
            }}
          >
            {questions.map((q: any, index: number) => 

                <ListItem
                  key={index}
                  sx={{
                    borderBottom: (theme) => (questions.length === (index + 1)) ? 'none' : `1px solid ${theme.palette.divider}`,
                  }}
                  secondaryAction={
                    <SecretAnswer value={q.answer} />
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText primary={(q.customQuestion) ? q.customQuestion : q.question} />
                  </ListItemButton>
                </ListItem>
              )}
          </List>
        }
      </CardContent>
    </Card>
  );
}