import React, { useRef } from 'react'
import { Button, Grow } from '@mui/material'
import Alert from '../Alert'
import { Add, CloudUploadOutlined } from '@mui/icons-material'
import { UploadErrorMessages } from '../../../constants/uploadErrorMessages'
import { styled } from '@mui/system'

/**
 * @component DocumentUploader
 * @description Uploading ui that returns an array of files selected with the browser file picker.
 */

export interface IProps {
  label?: string;
  maxSize: number;
  multiple?: boolean;
  hideButton?: boolean;
  startUploadFiles: (files: File[]) => void;
  setBase64?: (base64File: string | null) => void;
  docType: string;
  multipleDocType?: string[];
  text?: string;
}

const UploaderLabel = styled('label')(({ theme }) => ({
  background: theme.palette.grey[100],
  border: `dashed 2px ${theme.palette.divider}`,
  display: 'flex',
  color: theme.palette.primary.main,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  minHeight: '100px',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  userSelect: 'none',
  cursor: 'pointer',
  '*': {
    pointerEvents: 'none'
  },
  '&:hover': {
    boxShadow: `inset 0px 0px 16px 1px ${theme.palette.primary.light}`,
    borderColor: theme.palette.primary.main
  }
}));


export default function DocumentUploader(props: IProps) {
  const { docType, maxSize, startUploadFiles, label, multiple, multipleDocType, setBase64, text, hideButton } = props
  const fileInput: any = useRef(null)
  const [error, setError] = React.useState<string | null>(null)
  const [uploadedByDragging, setUploadedByDragging] = React.useState<boolean>(false)
  const handleDragEvents = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
    switch (e.type) {
      case 'dragenter':
        setUploadedByDragging(true)
        break
      case 'drop':
        handleFileDropped(e)
        break
      default:
        break
    }
  }

  const handleFileDropped = (e: any) => {
    setError(null)
    let files = []
    if (e.target.files && e.target.files.length > 0) {
      files = e.target.files
    }
    if (e.dataTransfer && e.dataTransfer.files.length > 0) {
      files = e.dataTransfer.files
    }
    if (docType === '.pdf') {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type !== 'application/pdf') {
          setError(UploadErrorMessages.NotPDF)
          return
        }
      }
    }
    if (docType === '.png') {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type !== 'image/png') {
          setError(UploadErrorMessages.NotPNG)
          return
        }
      }
    }

    if (maxSize) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > (maxSize * 1000000)) {
          setError(UploadErrorMessages.FileSizeLimit(maxSize))
          return
        }
      }
    }

    const forbiddenCharacters = /[$&+,/:;=?@]/;
    for (let i = 0; i < files.length; i++) {
      if (forbiddenCharacters.test(files[i].name)) {
        setError(UploadErrorMessages.ContainsForbiddenCharacter);
        return;
      }
    }
    if (multipleDocType) {
      let acceptedFileTypes = multipleDocType.join(', ').toUpperCase();
      for (let i = 0; i < files.length; i++) {
        if (!multipleDocType.includes(files[i].type) && files[i].type !== '') {
          if (label === 'Certificate') {
            acceptedFileTypes = '.pem,' + ' .pfx,' + ' .p12';
          }
          if (label === 'Seal') {
            acceptedFileTypes = '.png';
          }
          const errorMessage = 'Your ' + label + ' must be a ' + acceptedFileTypes + ' ' + 'file type'
          setError(errorMessage)
          return
        }
      }
    }

    if (multiple === false) {
      const reader = new FileReader()
      const file = uploadedByDragging ? e.dataTransfer.files[0] : e.target.files[0]
      reader.readAsDataURL(file)
      reader.onload = function () {
        if (setBase64) {
          const base64: any = reader.result;
          setBase64(base64);
        }
      }
    }

    if (startUploadFiles && files.length > 0) {
      startUploadFiles(files)
    }

    e.target.value = ''
  }
  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
  }
  return (
    <Grow in>
      <div>
        {!hideButton &&
          <Button
            fullWidth
            variant='contained'
            startIcon={<Add />}
            onClick={() => fileInput.current.click()}
          >
            Add files
          </Button>
        }

        <UploaderLabel
          onDrop={handleDragEvents}
          onDragEnter={handleDragEvents}
          onDragLeave={handleDragEvents}
          onDragOver={handleDragEvents}
          onMouseDown={handleMouseDown}
        >
          <input ref={fileInput} type='file' accept={(docType) ? docType : ''} hidden multiple={multiple} onChange={handleFileDropped} />
          <CloudUploadOutlined />
          <div>{text ? text : 'Drag and drop files here'}</div>
        </UploaderLabel>
        {error &&
          <Alert text={error} type='warning' />}
      </div>
    </Grow>
  )
}
