import { rest } from 'msw'
import { 
  mockedPackagesResponse, 
  mockedPackagesResponseAsc 
} from './models/mockPackage'

export const eSignHandlers = [
  // Handles GET /Packages request
  rest.get('https://esign.vision.dev.solex.com/Package', (req, res, ctx) => {
    // mocked search success
    const isDesc = req.url.searchParams.get('IsDescending')
    if (!isDesc) {
      return res(
        ctx.status(200),
        ctx.json({
          ...mockedPackagesResponseAsc
        })
      )
    }

    // mocked success without params
    return res(
      ctx.status(200),
      ctx.json({
        ...mockedPackagesResponse
      })
    )
  })
]