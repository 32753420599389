import { Alert, Box, Button, Typography } from "@mui/material";
import { ICreatePackageDetails, IPackageDetailsKBAQuestions } from "../../../../types/esign/createPackage";
import CPAddSigners from "../CPSigners";
import KBASignerCard from "../KBASignerCard";
import { EditOutlined } from "@mui/icons-material"
import UploadingGraphic from "../UploadingGraphic";
import { formatDate } from "../../../shared/utils/helpers";
import StepperHeader from "../../../shared/components/StepperHeader";
import DragAndDropList from "../../../shared/components/DragAndDropList";
import SectionHeader from "../../../shared/components/SectionHeader";

/**
 * @component UPPackageReview (UP stands for Upload Package)
 * @description eSign component used for reviewing the user entered required information to submit.
 * Also allows users to jump back to steps for editing.
 */


interface IProps {
  backStep?: (index?: number) => void;
  onSubmit?: () => void;
  data: ICreatePackageDetails
}

export default function UPPackageReview(props: IProps) {
  const { backStep, data, onSubmit } = props

  const handleBack = () => {
    if (backStep) {
      backStep()
    }
  }

  const handleEditSection = (index: number) => {
    if (backStep) {
      backStep(index)
    }
  }
  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit()
    }
  }

  if (data.isSubmitting) {

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: (theme) => theme.spacing(3) }}>
        <Typography align='center' variant='h4' color='primary'>Creating Package</Typography>
        <Typography align='center' variant='body1' sx={{ marginBottom: (theme) => theme.spacing(2) }}>Please wait while your package is created....</Typography>
        <Box>
          <UploadingGraphic />
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ padding: (theme) => theme.spacing(2) }}>

      <StepperHeader
        title='Review & Submit'
      />

      {data.createPackageError &&
        <Alert sx={{ marginBottom: (theme) => theme.spacing(2) }} variant='standard' severity='error'>{data.createPackageError}</Alert>
      }

      <SectionHeader
        title='Package Details'
        component={<Box>
          <Button onClick={() => handleEditSection(0)} startIcon={<EditOutlined />} variant='text' sx={{ color: 'white' }}>Edit Signers</Button>
          <Button onClick={() => handleEditSection(1)} startIcon={<EditOutlined />} variant='text' sx={{ color: 'white' }}>Edit Details</Button>
        </Box>}
      />

      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          padding: (theme) => theme.spacing(2)
        }}
      >
        <Box>
          <Typography>Package Name</Typography>
          <Typography
            variant='body2'
            sx={{
              color: 'primary.main',
              letterSpacing: '0.966667px'
            }}>
            {data?.packageDetails?.packageName}
          </Typography>
        </Box>

        <Box>
          <Typography>eSigner Expiration</Typography>
          <Typography
            variant='body2'
            sx={{
              color: 'primary.main',
              letterSpacing: '0.966667px'
            }}>
            {formatDate(data?.packageDetails?.eSignExp, 'MMM D, YYYY')}
          </Typography>
        </Box>

        <Box>
          <Typography>MFA Auth</Typography>
          <Typography
            variant='body2'
            sx={{
              color: 'primary.main',
              letterSpacing: '0.966667px'
            }}>
            {(data?.packageDetails?.enableMFA) ? 'Enabled' : 'Disabled'}
          </Typography>
        </Box>

      </Box>

      <Box
        sx={{
          padding: (theme) => theme.spacing(2)
        }}
      >
        {data.uploads.length > 0 &&
          <>
            <Typography variant='subtitle2' sx={{ marginBottom: (theme) => theme.spacing(2) }}>Security Questions</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: (theme) => theme.spacing(1)
              }}
            >
              {data?.signersList.map((signer, index) =>

                <Box
                  key={index}
                  sx={{
                    flex: 1
                  }}
                >
                  <KBASignerCard
                    readonly
                    firstName={signer.firstName}
                    lastName={signer.lastName}
                    questions={(() => {
                      const qs = data?.packageDetails?.kbaQuestions?.find((q: IPackageDetailsKBAQuestions) => q.email === signer.email)
                      if (qs) return qs.questions
                      return []
                    })()}
                  />
                </Box>

              )}
            </Box>
          </>
        }
      </Box>

      <SectionHeader
        title='Package Documents'
        component={<Button onClick={() => handleEditSection(2)} startIcon={<EditOutlined />} variant='text' sx={{ color: 'white' }}>Edit Uploads</Button>}
      />


      {data.uploads.length > 0 &&
        <DragAndDropList
          readonly
          columns={[
            { Header: 'Doc Name', accessor: 'name' }
          ]}
          data={data.uploads}
        />
      }

      {(data.packageDetails.templates.length > 0) &&
        <CPAddSigners data={data} readonly />
      }

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: (theme) => theme.spacing(2)
        }}
      >
        <Button variant='outlined' color='primary' onClick={() => handleBack()}>Back</Button>
        <Button variant='contained' color='success' sx={{background:(theme) => theme.palette.success.light}} onClick={() => handleOnSubmit()}>Submit</Button>
      </Box>

    </Box>

  )
}
