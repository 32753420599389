import {
  ICreatePackageDetails
} from '../../../types/esign/createPackage'

/**
 * @function validatePackageDetails
 * @description Checks if package details is fully completed
 */


export const validatePackageDetails = (
  createPackage: ICreatePackageDetails
) => {
  let needsQuestions = false
  let needsMFAPhoneNumbers = false
  if (
    createPackage.packageDetails.enableKBA &&
    createPackage.packageDetails.kbaQuestions.length === 0
  ) {
    needsQuestions = true
  }
  if (
    createPackage.packageDetails.enableMFA &&
    createPackage.signersList.find((signer) => signer.phoneNumber === '')
  ) {
    needsMFAPhoneNumbers = true
  }
  return (
    createPackage.packageDetails.consentWindow !== null &&
    createPackage.packageDetails.eSignExp !== null &&
    createPackage.packageDetails.sessionExp !== null &&
    createPackage.packageDetails.packageName !== null &&
    createPackage.packageDetails.packageName.trim() != '' &&
    !needsQuestions &&
    !needsMFAPhoneNumbers
  )
}

export default validatePackageDetails