import { IDocument } from '../../../types/Document'
import { DocDownloadType } from '../../enums'
import { axiosS3Instance } from './axios'

export const retrieveS3Document = async (url: string) => {
  return new Promise<any>((resolve, reject) => {
    axiosS3Instance
      .get(url)
      .then(response => {
        return new Blob([response.data], { type: 'application/pdf' })
      })
      .then(blob => {
        const _url = window.URL.createObjectURL(blob)
        window.open(_url, '_blank')
        URL.revokeObjectURL(_url)
        resolve(true) // Why can't I just resolve without returning anything?
      })
      .catch(error => {
        console.log(error,'errrrrror')
        reject(error)
      })
  })
}

export const findPresignedUrl = (
  documentsListDictionary: Record<string, IDocument[]>,
  documentMetaData: IDocument,
  documentType: DocDownloadType
): string => {
  const docMetaData = documentsListDictionary[documentType].find(
    (d: any) => d.FileName == documentMetaData.FileName
  )
  if (!docMetaData) {
    throw new Error('Unable to find request document')
  }

  return docMetaData.Url
}
