
import { CheckRounded, FormatListBulletedRounded } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { styled } from '@mui/system';


/**
 * @component CustomStepperTab 
 * @description A tab component used for the CustomSTepper.
 */

interface ICustomTabProps {
  completed?: string | boolean | null;
  index: number;
  label: string;
  lastStep?: boolean;
  activeStep?:boolean;
}

const CustomTab = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  '.MuiAvatar-root': {
    'svg': {
      width: '15px',
      height: '15px'
    }
  }
});

export default function CustomStepperTab(props: ICustomTabProps) {
  const {
    index,
    label,
    completed,
    lastStep,
    activeStep
  } = props

  const getIcon = () => {
    if (lastStep) {
      return <FormatListBulletedRounded />
    }
    return (completed) ? <CheckRounded /> : index + 1
  }
  return (
    <CustomTab>
      <Avatar sx={{
        width: '24px',
        height: '24px',
        fontSize: '12px',
        marginRight: '10px',
        background:(theme) => {
          if(activeStep && !completed) return theme.palette.primary.main
          if(completed) return theme.palette.success.light
        }
      }}
      >
        {getIcon()}
      </Avatar>
      {label}
    </CustomTab>

  )
}
