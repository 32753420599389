
import { Add } from '@mui/icons-material';
import { Avatar, MenuItem, Typography, Box, MenuList, Paper } from '@mui/material';

interface IProps {
  signers: any,
  close?: any
}


export default function AddSignerMenu(props: IProps) {
  const {
    signers,
    close
  } = props

  const handleClose = (signer: any) => {
    if (close) {
      close(signer);
    }
  };


  return (
    <Paper>
      <MenuList>
        <MenuItem
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'center'
          }}
          onClick={() => handleClose(null)}
        >
          <Add />
          <Typography textAlign='center' variant='button'>Create New</Typography>
        </MenuItem>
        {signers.map((signer: any, index: number) =>
          <MenuItem
            key={index}
            onClick={() => handleClose(signer)}
            sx={{ borderBottom: (signers.length !== index + 1) ? (theme) => `1px solid ${theme.palette.divider}` : 'none' }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              <Avatar sx={{ marginLeft: '10px', marginRight: '10px', fontSize: '16px', width: 35, height: 35 }}>{signer?.firstName?.charAt() + signer?.lastName?.charAt()}</Avatar>
              <Typography variant='subtitle1' sx={{ lineHeight: '0' }}>{signer?.firstName + ' ' + signer?.lastName}</Typography>
            </Box>
          </MenuItem>
        )}
      </MenuList>
    </Paper>
  )
}
