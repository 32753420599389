import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from '../../redux-store/hooks';
import { RootState } from "../../redux-store/store"
import { ILocalDocument } from "../../../types/esign/createPackage";
import { getDocumentStatusAsync } from "../../redux-store/esign/esignSlice";

const useDocumentStatusPolling = () => {
    const dispatch = useAppDispatch();
    const { uploads, uploadId, allUploadsComplete, createPackageError } = useAppSelector((state: RootState) => state.esign.createPackage);
    const [allUploaded, setAllUploaded] = useState(false);
    const [pollingTimer, setPollingTimer] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (!uploads.length || !uploadId) return;

        const errorUploading = uploads.every((upload: ILocalDocument) => upload.errorMessage != null);
        if (allUploadsComplete || errorUploading) {
            if (pollingTimer) {
                clearInterval(pollingTimer);
                setPollingTimer(null)
            }
            setAllUploaded(allUploadsComplete);
            return;
        }

        const isUploading = uploads.some((upload: ILocalDocument) => upload.uploading);
        if (isUploading) {
            dispatch(getDocumentStatusAsync(uploads));
        }

        if (!pollingTimer) {
            const timerId = setInterval(() => {
                dispatch(getDocumentStatusAsync(uploads));
            }, 5000);
            setPollingTimer(timerId)
        }

        return () => {
            if (pollingTimer) {
                clearInterval(pollingTimer);
                setPollingTimer(null)
            }
        };
    }, [uploads, uploadId, allUploadsComplete, createPackageError]);

    return allUploaded;
};

export default useDocumentStatusPolling;