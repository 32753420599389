// This is built around the concepts of https://mui.com/material-ui/api/tab-panel/ but the MUI version seems to have issues since
// Since it is experimental or something


interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number | boolean;
}

export function TabPanel (props: ITabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      style={{width:'100%'}}
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  )
}