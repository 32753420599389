import { Alert, Box, Button, IconButton } from "@mui/material";
import { ICreatePackageDetails, ICreatePackageSignerListValues } from "../../../../types/esign/createPackage";
import StepperHeader from "../../../shared/components/StepperHeader";
import { useState } from "react";
import AddSignerDialog from "../AddSignerDialog";
import { StepperActions } from "../../../enums";
import UserInfo from "../../../shared/components/UserInfo";
import { Add, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { validateAddSigners } from "../../utils/uploadPackageStepValidator";

/**
 * @component UPSigners (UP stands for Upload Package)
 * @description eSign component for adding signers for the upload package flow
 */

interface IProps {
  backStep?: () => void;
  nextStep?: () => void;
  data?: ICreatePackageDetails
  action?: (type: string, data: ICreatePackageDetails) => void;
}

export default function UPSigners(props: IProps) {
  const { data, action, nextStep } = props
  const [signerObj, setSignerObj] = useState<ICreatePackageSignerListValues | null>(null)

  const handleNext = () => {
    if (nextStep) {
      nextStep()
    }
  }

  const handleShowModal = (signer: ICreatePackageSignerListValues | null) => {
    if (signer) {
      setSignerObj(signer)
    } else {
      setSignerObj({
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        phoneNumber: ''
      })
    }

  }

  const handleUpdateSigner = (signerVals: ICreatePackageSignerListValues) => {
    const updatedArray: ICreatePackageSignerListValues[] = JSON.parse(JSON.stringify(data?.signersList))
    const existsIndex = updatedArray.findIndex((signer: ICreatePackageSignerListValues) => signer.email === signerObj?.email)

    if (existsIndex !== -1) {
      updatedArray[existsIndex] = signerVals
    } else {
      updatedArray.push(signerVals)
    }
    const update: ICreatePackageDetails = Object.assign({}, data, { signersList: updatedArray })


    if (action) {
      action(StepperActions.Update, update)
    }
    setSignerObj(null)
  }

  const handleRemoveSigner = (index: number) => {
    const updatedArray: ICreatePackageSignerListValues[] = JSON.parse(JSON.stringify(data?.signersList))
    updatedArray.splice(index, 1)
    const update: ICreatePackageDetails = Object.assign({}, data, { signersList: updatedArray })
    if (action) {
      action(StepperActions.Update, update)
    }
  }


  return (
    <Box padding={(theme) => theme.spacing(2)}>

      {signerObj &&
        <AddSignerDialog
          open={true}
          addedSigners={data?.signersList}
          signer={(signerObj) ? signerObj : null}
          edit={(signerObj?.email) ? true : false}
          close={() => {
            setSignerObj(null)
          }}
          submit={(vals: ICreatePackageSignerListValues) => handleUpdateSigner(vals)}
        />
      }

      <Box>
        <StepperHeader
          title='Add Signer(s)'
          buttonText='Add Signer'
          buttonClicked={() => handleShowModal(null)}
          buttonStartIcon={<Add />}
        />

        {data?.signersList.length === 0 &&
          <Alert severity='info'>Please add <strong>Signer(s)</strong> to continue</Alert>
        }

        {data?.signersList.map((signer: ICreatePackageSignerListValues, i: number) =>
          <Box
            key={i}
            display='flex'
            flexDirection='row'
            marginBottom={(theme) => theme.spacing(1)}
            paddingBottom={(theme) => theme.spacing(1)}
            justifyContent='space-between'
            alignItems='center'
            borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
          >

            <UserInfo
              primaryText={`${signer?.firstName} ${signer?.lastName}`}
              emailAddress={signer.email}
              phoneNumber={(signer?.phoneNumber && signer?.phoneNumber.length !== 0) ? signer.phoneNumber : undefined}
            />

            <Box>
              <IconButton onClick={() => handleRemoveSigner(i)}><DeleteOutline /></IconButton>
              <IconButton onClick={() => handleShowModal(signer)}><EditOutlined /></IconButton>
            </Box>
          </Box>
        )}

      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent:'flex-end',
          marginTop: (theme) => theme.spacing(2)
        }}
      >
        <Button variant='contained' color='primary' disabled={(data) ? !validateAddSigners(data) : false} onClick={() => handleNext()}>next</Button>
      </Box>
    </Box>
  )
}
