import { Typography } from '@mui/material'

export const AboutUs = () => {
  return (
    <>
      <Typography sx={{ fontSize: '14px' }}>
          Solex© is a secure technology platform enabling you to electronically receive and sign documents anywhere, anytime, and from any device.
        </Typography>
    </>
  )
}

export default AboutUs
