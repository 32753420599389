
import { styled } from '@mui/system';
import image from '../../../shared/assets/session-reconnecting.png'
import { Logo } from '../../../shared/components/Logo'
import {
  LinearProgress
} from '@mui/material'

const RFTContainer = styled('div')({
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100vw',
  height: '100vh',
  background: '#fff',
  zIndex: 999999999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const RFTGraphic = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '200px',
  position: 'relative',
  animationDuration: '.5s',
  animationName: '$slidein'
});

const RFTSpinner = styled('div')({
  width: '40%',
    height: '20px',
    position: 'absolute',
    top: '129px'
});

const RFTText= styled('div')({
  color: '#143B6B',
    textTransform: 'uppercase',
    fontSize: '15px',
    marginTop: '20px'
});

function RefreshingTokenOverlay () {
  return (
      <RFTContainer>
        <RFTGraphic>
          <RFTSpinner>
            <LinearProgress />
          </RFTSpinner>
          <Logo />
          <img style={{maxWidth: '200px'}} src={image} alt='Session Reconnecting Graphic' />
          <RFTText>Session Reconnecting...</RFTText>
        </RFTGraphic>
      </RFTContainer>
  )
}


export default RefreshingTokenOverlay
