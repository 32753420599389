import React from 'react'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import ls from 'local-storage'
import { SnackbarProvider } from 'notistack'
import { jwtDecode } from 'jwt-decode'
import AppRouter from './routers/AppRouter'
import './shared/styles/global-styles.scss'
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
import CustomTheme from './CustomTheme'
import CssBaseline from '@mui/material/CssBaseline'
import { store } from './redux-store/store'
import { localConfig } from './localConfig'
import { DocutechTheme } from 'docutech-serverless-ui'

import { setConfig } from './redux-store/global/globalSlice';
import { loginSuccess } from './redux-store/auth/authSlice';

import { worker } from './shared/__mocks__/browser'
import { injectStore } from './shared/utils/axios';
import { deepmerge } from '@mui/utils'
injectStore(store);

if (localConfig.features.enableMocks && process.env.NODE_ENV === 'development') {
  worker.start()
}

const idToken:any = ls('IdToken')

declare global {
  interface Window {
    __env:any
  }
}

const envVars = window.__env

if (idToken) {
  const userData:any = jwtDecode(idToken)
  userData.initials = userData.given_name?.charAt(0) + userData.family_name?.charAt(0)
  if( userData) {
    store.dispatch(loginSuccess(userData))
  }
}

if (envVars) {
  store.dispatch(setConfig(envVars))
}else{
  window.__env = localConfig
}

const mergedThemes = createTheme(deepmerge(DocutechTheme, CustomTheme));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <Provider store={store}>
    {/* @ts-ignore */}
    <SnackbarProvider maxSnack={6}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={mergedThemes}>
          <CssBaseline />
            <AppRouter />
        </ThemeProvider>
      </StyledEngineProvider>
    </SnackbarProvider>
  </Provider>
  </React.StrictMode>
);