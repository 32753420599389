import { ICreatePackageSubmit } from '../../../types/esign/createPackage'
import { axiosEsignInstance } from '../../shared/utils/axios'
import axios from 'axios'
import { IUserConfig } from '../../../types/userConfig'

/**
 *
 * @param {string} query string of query params to be used in API path
 * example API path: /Packages?term=&page=1&pageSize=15&isDescending=true&Statuses=open,inprogress
 * @param cancelToken
 * @param {object} config localConfig 
 * @returns {Promise} array of packages
 */
export const fetchPackages = (query: string | undefined, cancelToken:any, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const searchQuery = query ? `?${query}` : ''
    const axiosConfig: any = {
      method: 'get',
      url: config.esignApiUrl + '/Package' + searchQuery,
      cancelToken: cancelToken
    }
    axiosEsignInstance(axiosConfig)
      .then((response: any) => {
        resolve(response.data.result)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled
          // resolve('')
        } else {
          reject(error)
        }
      })
  })
}
/**
 *
 * @param {string} packageId string of query params to be used in API path
 * example API path: /Packages/(PACKAGEID)
 * @param {object} config localConfig 
 * @returns {Promise} array of packages
 */
export const fetchPackage = (packageId: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.esignApiUrl + `/Package/${packageId}`
    axiosEsignInstance.get(url)
      .then((response) => {
        resolve(response.data.result)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled
          // resolve('')
        } else {
          reject(error)
        }
      })
  })
}
/**
 *
 * @param {string} packageId string of query params to be used in API path
 * example API path: /Packages/(PACKAGEID)
 * @param {object} config localConfig 
 * @returns {Promise} array of packages
 */
export const reloadPackage = (packageId: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.esignApiUrl + `/Package/${packageId}`
    axiosEsignInstance.get(url)
      .then((response) => {
        resolve(response.data.result)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled
          // resolve('')
        } else {
          reject(error)
        }
      })
  })
}
/**
 *
 * example API path: /Packages
 * @param {object} readyPackage create package object to submit to the backend
 * @param {object} config localConfig
 * @returns {Promise} Create Package ARN will be sent back to check status on backend.
 */
export const submitPackage = (hasUpload:boolean, readyPackage: ICreatePackageSubmit, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const submitPathGenerate = '/Package/document-generate'
    const submitPathUpload = '/Package/document-upload'
    const url = `${config.esignApiUrl}${(hasUpload) ? submitPathUpload : submitPathGenerate}`
    axiosEsignInstance.post(url, readyPackage)
      .then((response) => {
        resolve(response.data.result)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled
          // resolve('')
        } else {
          const status = 'Error'
          const exceptionMessage = (error?.response?.data?.result) ? error?.response?.data?.result : error.message
          const resultMessage = (error?.response?.data?.result) ? error?.response?.data?.result : error.message
          if(error.response.status === 400){
            reject({status:status, message:resultMessage})
          }
          if(error.response.status === 500){
            reject({status:status, message:exceptionMessage})
          }
          reject({status:status, message:error.message})

        }
      })
  })
}
/**
 *
 * example API path: /Packages/unlock-session/{packageId}
 * @param {object} packageId package Id
 * @param {object} solexPartyId solex Party Id
 * @param {object} config localConfig
 * @returns {Promise} Returns 200 if successful
 */
export const unlockPackageParty = (packageId: string, solexPartyId:string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.esignApiUrl + `/Package/unlock-session/${packageId}`+'?solexPartyId=' + solexPartyId
    axiosEsignInstance.put(url, { solexPartyId: solexPartyId })
      .then((response) => {
        // After Response Reload Package
        reloadPackage(packageId, config)
        .then(() => {
          resolve(response.data.result)
        })
        .catch(() => {
          resolve(response.data.result)
        })
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled
          // resolve('')
        } else {
          const status = 'Error'
          const message = error?.message
          reject({status:status, message:message})

        }
      })
  })
}
/**
 *
 * example API path: /Packages/{packageId}/resend-party-link
 * @param {object} packageId package Id
 * @param {object} solexPartyId solex Party Id
 * @param {object} config localConfig
 * @returns {Promise} Returns 200 if successful
 */
export const resendPartyLink = (packageId: string, solexPartyId:string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.esignApiUrl + `/Package/${packageId}/resend-party-link`+'?solexPartyId=' + solexPartyId
    axiosEsignInstance.put(url, { solexPartyId: solexPartyId })
      .then((response) => {
        // After Response Reload Package
        reloadPackage(packageId, config)
        .then(() => {
          resolve(response.data.result)
        })
        .catch(() => {
          resolve(response.data.result)
        })
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled
          // resolve('')
        } else {
          const status = 'Error'
          const message = error?.message
          reject({status:status, message:message})
        }
      })
  })
}
/**
 *
 * example API path: /Package/retrieve-documents?
 * @param {object} sessionId package Id
 * @param {object} solexPartyId solex Party Id
 * @param {object} config localConfig
 * @returns {Promise} Returns 200 if successful
 */
export const fetchPackageDocumentsList = (query: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const axiosConfig: any = {
      method: 'get',
      url: config.esignApiUrl + '/Package/retrieve-documents?' + query,
    }
    axiosEsignInstance(axiosConfig)
      .then((response: any) => {
        resolve(response.data.result)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled
          // resolve('')
        } else {
          reject(error)
        }
      })
  })
}
/**
 *
 * example API path: /Package/${packageId}/documents/audit-log
 * @param {object} packageId package Id
 * @param {object} config localConfig
 * @returns {Promise} Returns 200 if successful
 */
export const fetchPackageAuditLog = (sessionId: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const axiosConfig: any = {
      method: 'get',
      url: config.esignApiUrl + `/Package/${sessionId}/documents/audit-log`,
    }
    axiosEsignInstance(axiosConfig)
      .then((response: any) => {
        resolve(response.data.result)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled
          // resolve('')
        } else {
          reject(error)
        }
      })
  })
}

/**
 *
 * example API path: /Package/${packageId}/documents
 * @param {object} packageId packageId
 * @param {boolean} mergeDocs
 * @param {object} config localConfig
 * @returns {Promise} Returns 200 if successful
 */
export const fetchPackageDocs = (packageId: string, mergeDocs: boolean, config: any): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    const axiosConfig: any = {
      method: 'get',
      url: config.esignApiUrl + `/Package/${packageId}/documents?mergeDocs=${mergeDocs}`,
    }
    axiosEsignInstance(axiosConfig)
      .then((response: any) => {
        resolve(response.data.result.documentList)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled
          // resolve('')
        } else {
          reject(error)
        }
      })
  })
}


/**
 *
 * @param {object} config localConfig 
 * @returns {Promise} users configuration for using templates and or document uploads 
 */
export const fetchCreatePackageUserConfig = (config: any) => {
  return new Promise<any>((resolve, reject) => {
    const axiosConfig: any = {
      method: 'get',
      url: config.esignApiUrl + '/Configuration'
    }
    axiosEsignInstance(axiosConfig)
      .then((response: any) => {
        const userConfigs:IUserConfig[] = response?.data?.result
        const isTemplateEnabled:IUserConfig | undefined = userConfigs.find((userConfig:IUserConfig) => userConfig?.configurationKey === 'IsTemplateEnabled' && userConfig?.configurationValue === 'true')
        const isDocUploadEnabled:IUserConfig | undefined = userConfigs.find((userConfig:IUserConfig) => userConfig?.configurationKey === 'IsDocUpload' && userConfig?.configurationValue === 'true')
        resolve({
          isTemplateEnabled:!!(isTemplateEnabled),
          isDocUploadEnabled:!!(isDocUploadEnabled)
        })
      })
      .catch((error) => {
        reject(error?.message)
      })
  })
}
