
import {
  Box,
  CircularProgress
} from '@mui/material'

interface IProps {
  text?: string
}

const Loading = (props:IProps) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <h3>{(props.text) ? props.text : 'loading...'}</h3>
      <CircularProgress />
    </Box>
  )
}

export default Loading
