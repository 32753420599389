import {
  ICreatePackageDetails,
  ICreatePackageSignerListValues,
  ICreatePackageSignerObject,
  ICreatePackageSubmit,
  ICreatePackageSubmitKBA,
  ICreatePackageSubmitSigner,
  ICreatePackageSubmitUploadedFile,
  IPackageDetailsKBAQuestions
} from '../../../types/esign/createPackage'

export const prepPackage = (data: ICreatePackageDetails) => {
  const dataTemplateSignersExtract: any = extractTemplateSigners(data)
  const prepedPackage: ICreatePackageSubmit = {
    name: data.packageDetails.packageName,
    expiredDateUtc: data.packageDetails.sessionExp,
    consentExpirationDateUtc: data.packageDetails.eSignExp,
    signingExpirationDateUtc: data.packageDetails.eSignExp,
    isMfaEnabled: data.packageDetails.enableMFA,
    signers: dataTemplateSignersExtract.signers,
    packageTemplates: dataTemplateSignersExtract.packageTemplates
  }
  if(data.uploadId && data.uploads && data.uploads.length !==0) {
    const prepedUploads:ICreatePackageSubmitUploadedFile[] = []
    for (let i = 0; i < data.uploads.length; i++) {
      prepedUploads.push({ order: i+1, documentName: data.uploads[i].name })
    }
    prepedPackage['uploadId'] = data.uploadId
    prepedPackage['documents'] = prepedUploads

    prepedPackage.signers = extractSignersFromSignersList(data);

  }
  return prepedPackage
}

export const extractTemplateSigners = (data: ICreatePackageDetails) => {
  const signerArray: ICreatePackageSubmitSigner[] = []
  const templateSignersObject: any = []

  for (let i = 0; i < data.packageDetails.templates.length; i++) {
    const template = data.packageDetails.templates[i]
    for (
      let itemplate = 0;
      itemplate < template.templateSigners.length;
      itemplate++
    ) {
      const templateSigner: ICreatePackageSignerObject =
        template.templateSigners[itemplate]
      const signer: ICreatePackageSignerListValues | undefined =
        data.signersList.find(
          (signer: ICreatePackageSignerListValues) =>
            signer.email === templateSigner.signerEmail
        )


      const templateExistsIndex: number = templateSignersObject.findIndex(
        (target: any) => target.id === template?.id
      )
      if (templateSigner?.signerEmail) {
        if (templateExistsIndex !== -1) {
          templateSignersObject[templateExistsIndex].templateSignerData.push({
            email: templateSigner.signerEmail,
            signerType: templateSigner.signerDisplayName
          })
        } else {
          templateSignersObject.push({
            id: template.id,
            templateName: template.displayName,
            templateSignerData: [
              {
                email: templateSigner.signerEmail,
                signerType: templateSigner.signerDisplayName
              }
            ]
          })
        }
      }

      const singerExists: ICreatePackageSubmitSigner[] = signerArray.filter(
        (target: any) => target.emailAddress === signer?.email
      )
      if (signer && singerExists.length === 0) {
        const extractedSecurityQuestions = extractSecurityQuestions(
          data.packageDetails.enableKBA,
          signer,
          data.packageDetails.kbaQuestions
        )
        signerArray.push({
          firstName: signer.firstName,
          middleName: signer.middleName,
          lastName: signer.lastName,
          suffix: signer?.suffix ? signer.suffix : '',
          emailAddress: signer.email,
          phoneNumber: signer.phoneNumber.replace(/[^\d]/g, ''),
          isKbaEnabled:(extractedSecurityQuestions.length > 0),
          securityQuestions: extractedSecurityQuestions
        })
      }
    }
  }
  return { signers: signerArray, packageTemplates: templateSignersObject }
}


export const extractSignersFromSignersList = (data: ICreatePackageDetails) => {
  const signerArray: ICreatePackageSubmitSigner[] = []

  for (let i = 0; i < data.signersList.length; i++) {
    const signer = data.signersList[i]
    const extractedSecurityQuestions = extractSecurityQuestions(
      data.packageDetails.enableKBA,
      signer,
      data.packageDetails.kbaQuestions
    )
    signerArray.push({
      firstName: signer.firstName,
      middleName: signer.middleName,
      lastName: signer.lastName,
      suffix: signer?.suffix ? signer.suffix : '',
      emailAddress: signer.email,
      phoneNumber: signer.phoneNumber.replace(/[^\d]/g, ''),
      isKbaEnabled:(extractedSecurityQuestions.length > 0),
      securityQuestions: extractedSecurityQuestions
    })
    
  }

  return signerArray
}

export const extractSecurityQuestions = (isKbaEnabled:boolean, signer: any, kbaArray: any) => {
  const questionsArray: ICreatePackageSubmitKBA[] = []
  if(!isKbaEnabled) return questionsArray
  for (let i = 0; i < kbaArray.length; i++) {
    const kba: IPackageDetailsKBAQuestions = kbaArray[i]
    if (kba.email === signer.email) {
      for (let i2 = 0; i2 < kba.questions.length; i2++) {
        const kbaQuestion = kba.questions[i2]
        questionsArray.push({
          answer: kbaQuestion.answer,
          question: (kbaQuestion.question === 'Custom') ? kbaQuestion.customQuestion : kbaQuestion.question
        })
      }
    }
  }
  return questionsArray
}
