import { localConfig } from '../../localConfig'
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface GlobalState {
  config: any
}
const initialState: GlobalState = {
  config: localConfig
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setConfig: (state:any, action: PayloadAction<Record<string, unknown>>) => {
      state.config = action.payload;
    }
  }
});

export const { setConfig } = globalSlice.actions;
export const selectConfig = (state: RootState) => state.global.config;

export default globalSlice.reducer;
