
import { Box } from '@mui/material';
import { useState } from 'react';

interface IProps {
  value: string,
}


export default function SecretAnswer(props: IProps) {
  const { value } = props
  const [showValue, setShowValue] = useState(false)

  const getHiddenValue = () => {
    let val = ''
    for (let i = 0; i < value.length; i++) {
      val += '*'

    }
    return val
  }
  return (
    <Box
      onMouseEnter={() => setShowValue(true)}
      onMouseLeave={() => setShowValue(false)}
      sx={{
        userSelect: 'none',
        fontSize: '12px',
        fontWeight: 'bold',
        color: theme => theme.palette.primary.main
      }}
    >
      {showValue ? (
        value
      ) : (
        getHiddenValue()
      )}
    </Box>

  )
}
