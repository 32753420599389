import { Box, Button, Alert, Popover } from "@mui/material";
import TemplateSigners from "../TemplateSigners";
import { useState } from "react";
import AddSignerDialog from "../AddSignerDialog";
import { ICreatePackageDetails, ICreatePackageSignerListValues, ICreatePackageSignerObject } from "../../../../types/esign/createPackage";
import AddSignerMenu from "../AddSignerMenu";
import { validateAddSigners } from "../../utils/createPackageStepValidator";
import StepperHeader from "../../../shared/components/StepperHeader";

/**
 * @component CPSigners (CP stands for Create Package)
 * @description eSign component for adding signers for the create package flow
 */

interface IProps {
  backStep?: () => void;
  nextStep?: () => void;
  data: ICreatePackageDetails
  updatePackage?: any,
  readonly?: boolean
}

export default function CPSigners(props: IProps) {
  const { backStep, nextStep, data, updatePackage, readonly } = props
  const [signerObject, setSignerObject] = useState<null | any>(null)
  const [editSigner, setEditSigner] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


  const open = Boolean(anchorEl);

  const handleBack = () => {
    if (backStep) {
      backStep()
    }
  }

  const handleNext = () => {
    if (nextStep) {
      nextStep()
    }
  }

  const handleSubmitSigner = (vals: any) => {
    const update: ICreatePackageDetails = JSON.parse(JSON.stringify(data))
    const doesSignerExist = update.signersList.find((signer: any) => signer?.email === vals?.email)

    if (!doesSignerExist && !editSigner) {
      const newSigner: ICreatePackageSignerListValues = { ...vals }
      update.signersList.push(newSigner)
    }

    // Update Signer In Signer List
    if (editSigner) {
      for (let i = 0; i < update.signersList.length; i++) {
        if (update.signersList[i].email === signerObject?.email) {
          update.signersList[i] = vals
        }
      }
    }

    for (let i = 0; i < update.packageDetails.templates.length; i++) {
      const template = update.packageDetails.templates[i]
      for (let i2 = 0; i2 < template.templateSigners.length; i2++) {
        const addSignerToTemplate = { ...template.templateSigners[i2] };
        if (addSignerToTemplate.signerEmail !== undefined && addSignerToTemplate.signerEmail === signerObject.email
          || addSignerToTemplate.signerDisplayName === signerObject.signerDisplayName) {
          addSignerToTemplate.signerEmail = vals.email
          update.packageDetails.templates[i].templateSigners[i2] = addSignerToTemplate
        }
      }
    }
    updatePackage(update)
    setEditSigner(false)
    setSignerObject(null)
  }

  const handleRemoveSigner = (templateIndex: number, signer: ICreatePackageSignerObject) => {
    const update: ICreatePackageDetails = JSON.parse(JSON.stringify(data))
    let useCount = 0

    // Loop and set values
    for (let i2 = 0; i2 < update.packageDetails.templates[templateIndex].templateSigners.length; i2++) {
      if (
        update?.packageDetails?.templates[templateIndex]?.templateSigners[i2]?.signerEmail === signer?.signerEmail &&
        update.packageDetails.templates[templateIndex].templateSigners[i2]?.signerDisplayName === signer.signerDisplayName
      ) {
        delete update.packageDetails.templates[templateIndex].templateSigners[i2].signerEmail
      }
    }

    // Check if signer is used in other templates
    for (let i = 0; i < update.packageDetails.templates.length; i++) {
      const template = update.packageDetails.templates[i];
      for (let i2 = 0; i2 < template.templateSigners.length; i2++) {
        if (
          template?.templateSigners[i2]?.signerEmail === signer?.signerEmail
        ) {
          useCount += 1
        }
      }
    }

    // If found remove from list
    if ((useCount === 0)) {
      const targetIndex = update.signersList.findIndex((targetSigner: ICreatePackageSignerListValues) => targetSigner.email === signer?.signerEmail)
      update.signersList.splice(targetIndex, 1)
    }

    updatePackage(update)
  }

  const handleClickShowSignerMenu = (event: React.MouseEvent<HTMLElement>, signer: any) => {
    if (data.signersList.length === 0) {
      setSignerObject(signer)
    } else {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setSignerObject(signer)
    }
  };

  const handleShowCreateNewSigner = (signer: any | null) => {
    if (!signer) {
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
      handleSubmitSigner(signer)
    }
  }

  const checkComplete = (signers: any) => {

    if (signers.length === 0) { return false }

    for (let i = 0; i < signers.length; i++) {
      const signer = signers[i];
      if (signer.isSignerRequired && !signer.signerEmail) {
        return false
      }
      if (i + 1 === signers.length) {
        return true
      }
    }

  }

  /**
   * Appends signer details if assigned
   **/
  const getSignerDetails = (signers: ICreatePackageSignerObject[]) => {
    const results: any[] = []
    for (let i = 0; i < signers.length; i++) {
      const intersection: any = data.signersList.find(signer => signer?.email === signers[i]?.signerEmail);
      if (intersection) {
        results.push({ signer: signers[i], values: intersection })
      } else {
        results.push({ signer: signers[i], values: intersection })
      }
    }
    return results
  }

  return (
    <Box sx={{ padding: '15px' }}>

      {!readonly &&
        <>
          {(!anchorEl && signerObject) &&
            <AddSignerDialog
              open={true}
              edit={editSigner}
              addedSigners={data.signersList}
              signer={(signerObject) ? signerObject : null}
              close={() => {
                setSignerObject(null)
                setEditSigner(false)
              }}
              submit={(vals: any) => handleSubmitSigner(vals)}
            />
          }

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null)
              setSignerObject(null)
              setEditSigner(false)
            }}
          >
            <AddSignerMenu
              signers={data.signersList}
              close={handleShowCreateNewSigner}
            />
          </Popover>

          <StepperHeader
            title='Add Signers'
          />

          <Alert variant='standard' severity='info'>
            Please add <strong>ALL REQUIRED</strong> signers to each template below
          </Alert>
        </>
      }

      {data?.packageDetails?.templates.map((template: any, index: number) =>
        <TemplateSigners
          readonly={readonly}
          key={index}
          templateName={template.displayName}
          signers={getSignerDetails(template.templateSigners)}
          addSigner={(e: React.MouseEvent<HTMLElement>, signer: any) => handleClickShowSignerMenu(e, signer)}
          editSigner={(signer: any) => {
            setEditSigner(true)
            setSignerObject(signer)
          }}
          removeSigner={(signer: ICreatePackageSignerObject) => handleRemoveSigner(index, signer)}
          completed={checkComplete(template.templateSigners) as boolean}
        />
      )}

      {!readonly &&
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: (theme) => theme.spacing(2)
          }}
        >
          <Button variant='outlined' color='primary' onClick={() => handleBack()}>back</Button>
          <Button variant='contained' color='primary' disabled={!validateAddSigners(data)} onClick={() => handleNext()}>next</Button>
        </Box>
      }

    </Box>
  )
}
